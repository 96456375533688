<template>
  <v-text-field
    hide-details
    outlined
    dense
    @input="$emit('input', $event)"
    :value="value"
    @keyup="$emit('keyup', $event)"
    @change="$emit('change', $event)"
    v-bind="$attrs"
    autocomplete="nope"
    :loading="loading"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      default: '',
    },
    loading: {
      default: false
    }
  },
};
</script>
