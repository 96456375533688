import SuppliersService from './../services/suppliers-service';
import { mensageTest } from '@/utils/helpers/testPermission'
import * as types from './mutation-types';

export default {
  createSupplier: async ({ commit }, { supplier }) => {
    const response = await SuppliersService.create(supplier);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.CREATE_SUPPLIER, { supplier: response.data });

    return response;
  },
  registerImportedNf: async ({ commit }, { order }) => {
    const response = await SuppliersService.registerImportedNf(order);

    commit(types.EDIT_SUPPLIER, { supplier: response.data });

    return response;
  },
  editSupplier: async ({ commit }, { supplier }) => {
    const response = await SuppliersService.edit(supplier);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.EDIT_SUPPLIER, { supplier: response.data });

    return response;
  },
  deleteSupplier: async ({ commit }, { supplier = {}, id = null }) => {
    if (!supplier.id) supplier.id = id;
    await SuppliersService.delete(supplier.id);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.DELETE_SUPPLIER, { supplier });
    commit(types.UPDATE_PRODUCTS_AFTER_DELETE_SUPPLIER, { supplier });
  },
  getSuppliers: async ({ commit }) => {
    try {
      const response = await SuppliersService.getAll();

      commit(types.LIST_SUPPLIERS, { suppliers: response.data });
      commit(types.SET_LOADING, { loading: false });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  }
};
