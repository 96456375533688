import FinancialCategoryService from '../services/financial-category-service';
import FinancialTransactionService from '../services/financial-transaction-service';
import DevolutionService from '../services/devolutions-service.js'
// import BillsService from '../../bills/services/bills-service'
import * as BillsTypes from '@/modules/financial/submodules/bills/store/mutation-types'
import * as ClientTypes from '@/modules/clients/store/mutation-types'
import * as ProductsTypes from '@/modules/products/store/mutation-types'
import * as OrdersTypes from '@/modules/orders/store/mutation-types'
import * as types from './mutation-types';
import { mensageTest } from '@/utils/helpers/testPermission'

export default {
  createFinancialCategory: async ({ commit }, { financialCategory }) => {
    const response = await FinancialCategoryService.create(financialCategory);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.CREATE_FINANCIAL_CATEGORIES, {
      financialCategory: response.data
    });

    return response.data;
  },
  updateFinancialCategory: async ({ commit }, { financialCategory }) => {
    const response = await FinancialCategoryService.update(
      financialCategory
    );
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.EDIT_FINANCIAL_CATEGORIES, {
      financialCategory: response.data
    });

    return response.data;
  },
  createTransferBetweenAccounts: async ({ commit }, { data }) => {
    const response = await FinancialTransactionService.createTransferBetweenAccounts(
      data
    );
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.CREATE_TRANSFER_BETWEEN_ACCOUNTS, {
      transactions: response.data
    });

    return response.data;
  },
  createFinancialTransaction: async ({ commit }, { financialTransaction }) => {
    const response = await FinancialTransactionService.create(
      financialTransaction
    );
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.CREATE_FINANCIAL_TRANSACTION, {
      financialTransaction: response.data
    });

    return response.data;
  },
  updateFinancialTransaction: async ({ commit }, { financialTransaction }) => {
    const response = await FinancialTransactionService.update(
      financialTransaction
    );
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.EDIT_FINANCIAL_TRANSACTION, {
      financialTransaction: response.data
    });

    return response.data;
  },
  getFinancialTransactions: async ({ commit }, {page, limit, way, date, type, accountId, categoriesId, search, all}) => {
    const response = await FinancialTransactionService.getAll(page, limit, way, date, type, accountId, categoriesId, search, all);
    commit(types.LIST_FINANCIAL_TRANSACTION, {
      financialTransactions: response.data,
      type:all
    });
  },
  getTransactionsTablesLength: async ({ commit }, {search, date, type, accountId, categoriesId}) => {
    const response = await FinancialTransactionService.getTransactionsLength(search, date, type, accountId, categoriesId);
    // console.log(response)
    commit(types.TABLES_TRANSACTIONS_LENGTH, { transactions: response.data });
  },
  deleteFinancialTransaction: async (
    { commit },
    { financialTransactionId }
  ) => {
    try {
      const response = await FinancialTransactionService.delete(financialTransactionId);
      if(response.status == 405){ // permission error
        mensageTest(response)
      }
      if (response.status >= 200 && response.status < 300) {
        commit(types.DELETE_FINANCIAL_TRANSACTION, {
          financialTransactionId
        });
      }

      commit(`orders/${OrdersTypes.EDIT_ORDER}`, { order: response.data.order }, { root: true });
      return response.status
    } catch (err) {
      return err
    }
  },
  deleteFinancialTransactionByOrderId: async (
    { commit },
    { orderId }
  ) => {
    try {
      const response = await FinancialTransactionService.deleteByOrderId(orderId);
      if(response.status == 405){ // permission error
        mensageTest(response)
      }
      console.log(response)
      if (response.status >= 200 && response.status < 300) {
        commit(types.DELETE_FINANCIAL_TRANSACTION, {
          financialTransactionId: response.data.id
        });
      }

      commit(`orders/${OrdersTypes.EDIT_ORDER}`, { order: response.data.order }, { root: true });
      return response.status
    } catch (err) {
      return err
    }
  },
  deleteFinancialCategory: async (
    { commit },
    categoryId
  ) => {
    let response = await FinancialCategoryService.delete(categoryId);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.DELETE_FINANCIAL_CATEGORY, {
      categoryId
    });
  },
  getAllFinancialCategories: async ({ commit }) => {
    try {
      const response = await FinancialCategoryService.getAll();

      commit(types.LIST_FINANCIAL_CATEGORIES, {
        financialCategories: response.data
      });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },

  getAllDevolutions: async ({ commit }) => {
    const response = await DevolutionService.getAll();

    commit(types.LIST_DEVOLUTIONS, {
      devolutions: response.data
    });
  },

  createDevolution: async ({ commit }, data) => {
    const response = await DevolutionService.create(
      data
    );
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.CREATE_DEVOLUTION, {
      devolution: response.data.createdDevolution,
      financialTransaction: response.data.createdFinancialTransaction,
      orderProduct: response.data.updatedOrderProduct
    });

    if (response.data.updatedBills) {
      commit(BillsTypes.UPDATE_BILLS_VALUE, {
        bills: response.data.updatedBills
      });
    }

    if (response.data.updatedClient) {
      commit(`clients/${ClientTypes.EDIT_CLIENT}`, { client: response.data.updatedClient }, { root: true });
    }

    if (response.data.updatedProduct) {
      commit(`products/${ProductsTypes.EDIT_PRODUCT}`, { product: response.data.updatedProduct }, { root: true });
    }

    return response.data;
  },

  revertDevolution: async (
    { commit },
    { devolution }
  ) => {
    try{
    const response = await DevolutionService.update(devolution);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.DECREASE_RETURNED_QUANTITY, {
      orderProduct: response.data.updatedOrderProduct
    });
    if (response.data.updatedBills) {
      commit(BillsTypes.UPDATE_BILLS_VALUE, {
        bills: response.data.updatedBills
      });
    }
    if (response.data.updatedClient) {
      commit(`clients/${ClientTypes.EDIT_CLIENT}`, { client: response.data.updatedClient }, { root: true });
    }
    if (response.data.updatedProduct) {
      commit(`products/${ProductsTypes.EDIT_PRODUCT}`, { product: response.data.updatedProduct[0] }, { root: true });
    }
  }catch (err){
    return err
  }
  },
};
