let baseURL;
if (process.env.NODE_ENV == 'development') {
  //Your IP or localhost
  baseURL = 'http://localhost:3005';
} else {
  //Prod
  baseURL = process.env.API_URL;
}

// baseURL = 'http://localhost:3005';

export default baseURL;
