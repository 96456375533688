import {
  mapProducts,
  mapProduct,
  mapCategories,
  mapVariations
} from './../utils/helpers/products';
/* eslint eqeqeq: "off" */

export default {
  products: state => mapProducts(state.products, state.categories, state.tributesGroups),
  getProductsInternalCode: state => {
    let products = mapProducts(state.products, state.categories, state.tributesGroups);
    products = products.map((p) => {
      return p.code
    })
    return products
  },
  productsWithoutImage: state => {
    const products = mapProducts(state.products, state.categories, state.tributesGroups);

    return products.filter(p => !p.photo && p.isExternal == false);
  },
  getProduct: state => id =>
    mapProduct(state.products.find(product => product.id == id)),

  getProductWithoutMasks: (state, rootGetters) => id => {
    let product = state.products.find(product => product.id == id)
    let count = 0
    product.suppliers.map((supp) => {
      if (typeof supp == 'number') {
        product.suppliers[count] = rootGetters.getSupplier(supp)
      }
      count++
    })
    return product
  },
  categories: state => mapCategories(state.categories),
  getCategory: state => (categoryId) => {
    return state.categories.find(category => category.id == categoryId);
  },
  variations: state => mapVariations(state.variations),
  getVariation: state => (variationId) => {
    return state.variations.find(variation => variation.id == variationId);
  },
  getNcmList: state => {
    return state.ncmList
  },
  tablesLength: state => {
    return { productsLength: state.productsLength}
  },
  allProducts: state => {
    return mapProducts(state.allProducts, state.categories, state.tributesGroups)
  }
};
