
const SupplierPageDetails = () => import('./../views/SupplierPageDetails');

export default [
  {
    path: 'fornecedor/:id/',
    name: 'supplier-details',
    component: SupplierPageDetails
  }
];
