import TributeGroupsService from '../services/tributes-groups-service';
import * as types from './mutation-types';
import { mensageTest } from '@/utils/helpers/testPermission'
export default {
  createTributeGroup: ({ commit }, { tributeGroup }) => {
    return new Promise((resolve, reject) => {
      TributeGroupsService.create(tributeGroup)
        .then(response => {
          if(response.status == 405){ // permission error
            mensageTest(response)
          }
          commit(types.CREATE_TRIBUTE_GROUP, { tributeGroup: response.data });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  editTributeGroup: ({ commit }, { tributeGroup }) => {
    return new Promise((resolve, reject) => {
      TributeGroupsService.edit(tributeGroup)
        .then(response => {
          if(response.status == 405){ // permission error
            mensageTest(response)
          }
          commit(types.EDIT_TRIBUTE_GROUP, { tributeGroup: response.data });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  deleteTributeGroup: async ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      TributeGroupsService.delete(id)
        .then(response => {
          if(response.status == 405){ // permission error
            mensageTest(response)
          }
          commit(types.DELETE_TRIBUTE_GROUP, { id });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  getAllTributesGroups: async ({ commit }) => {
    const { data: tributesGroups } = await TributeGroupsService.getAll();
    commit(types.LIST_TRIBUTES_GROUPS, { tributesGroups });
  }
};
