/* Url plugin: https://vuejsexamples.com/toggle-switch-for-vue-js/ */
import Vue from 'vue';
import { VueMaskDirective } from 'v-mask';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { VTextField, VAutocomplete } from 'vuetify/lib'; //Globally import VTextField

import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

Vue.component('vue-draggable-resizable', VueDraggableResizable);
Vue.component('v-text-field', VTextField);

// Vue.use(ToggleSwitch);
Vue.use('mask', VueMaskDirective);
Vue.use(VueApexCharts);

/* Vue.use(VCurrencyField, {
  locale: 'pt-BR',
  precision: 2,
  decimalLength: 2,
  currency: "BRL",
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
}); */

//'v-autocomplete',

// console.log(VAutocomplete);
// Vue.component('v-autocomplete-v', {
//   extends: VAutocomplete,
//   props: {
//     'no-data-text': {
// 		type: String,
// 		default: 'HAHAAHAHA'
// 	}
//   }
// });

Vue.component('apexchart', VueApexCharts);
