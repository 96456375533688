const mapTags = (tags) => {
  return tags.map((tag) => {
    return tag;
  });
};
const mapTag = (tag) => {
  return {
    ...tag,
  };
};

export default {
  tags: (state) => {
    const { tags } = state;
    return mapTags(tags);
  },
  getTag: (state) => (id) => {
    const tag = state.tags.find((tag) => tag.id == id);
    return mapTag(tag);
  },
};
