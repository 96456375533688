import { render, staticRenderFns } from "./ButtonCard.vue?vue&type=template&id=78383f35&scoped=true&"
var script = {}
import style0 from "./ButtonCard.vue?vue&type=style&index=0&id=78383f35&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78383f35",
  null
  
)

export default component.exports