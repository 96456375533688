import * as types from './mutation-types';

export default {
  [types.CREATE_CASH_BOX]: (state, { cashBox }) => {
    state.cashBoxes.push(cashBox);
  },
  [types.CREATE_OPERATION]: (state, { operation }) => {
    const cashBoxIndex = state.cashBoxes.findIndex(
      cashbox => cashbox.id == operation.cashBoxId
    );

    // const cashBox = state.cashBoxes[cashBoxIndex];

    const cashBox = state.loggedCashBox
    cashBox.operations.push(operation);
    cashBox.lastOperation = operation;
    state.cashBoxes.splice(cashBoxIndex, { ...cashBox });
  },
  [types.LIST_CASH_BOXES]: (state, { cashBoxes }) => {
    state.cashBoxes = cashBoxes;
  },
  [types.CASH_BOX_OPERATIONS_TABLE_LENGTH]: (state, { tableLength }) => {
    state.cashBoxOperationsLength = tableLength;
  },
  [types.GET_LOGGED_CASH_BOX]: (state, { cashBox }) => {
    state.loggedCashBox = cashBox
  },
  [types.EDIT_CASH_BOX]: (state, { cashBox }) => {
    const index = state.cashBoxes.findIndex(t => t.id === cashBox.id);
    if (+index >= 0) {
      state.cashBoxes.splice(index, 1, cashBox);
    }
  },
  [types.LIST_CASH_BOX_OPERATIONS_BY_ID]: (state, { cashBoxId, operations }) => {
    const index = state.cashBoxes.findIndex(t => t.id === cashBoxId);
    
    if (+index >= 0) {
      state.cashBoxes[index].operations = operations
    }
  }
  
};
