import ClientsService from './../services/clients-service';
import ClientsDebitGroupsService from './../services/clients-debit-groups-service';
import { mensageTest } from '@/utils/helpers/testPermission';
import * as types from './mutation-types';
import clientsService from './../services/clients-service';

export default {
  createClient: ({ commit }, { client }) => {
    return new Promise((resolve, reject) => {
      ClientsService.create(client)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.CREATE_CLIENT, { client: response.data });
          resolve(response.data);
        })
        .catch((error) => reject(error));
    });
  },
  createClientsDebitGroup: async (
    { commit, dispatch },
    { clientsDebitGroup }
  ) => {
    const response = await ClientsDebitGroupsService.create(clientsDebitGroup);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.CREATE_CLIENT_DEBIT_GROUP, {
      clientsDebitGroup: response.data,
    });

    dispatch('clients/getClientsDebitGroups', {}, { root: true });

    return response;
  },
  editClientsDebitGroup: async (
    { commit, dispatch },
    { clientsDebitGroup }
  ) => {
    const response = await ClientsDebitGroupsService.edit(clientsDebitGroup);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_CLIENT_DEBIT_GROUP, {
      clientsDebitGroup: response.data,
    });

    dispatch('clients/getClientsDebitGroups', {}, { root: true });

    return response;
  },
  deleteClientsDebitGroup: async ({ commit }, clientsDebitGroupId) => {
    const response = await ClientsDebitGroupsService.delete(
      clientsDebitGroupId
    );
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }

    commit(types.DELETE_CLIENT_DEBIT_GROUP, {
      clientsDebitGroupId,
    });

    return response;
  },
  editClient: async ({ commit }, { client }) => {
    const response = await ClientsService.edit(client);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_CLIENT, { client: response.data });

    return response.data;
  },
  deleteClient: async ({ commit }, { client }) => {
    // console.log(client.id);
    await ClientsService.delete(client.id);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.DELETE_CLIENT, { client });
  },
  getClientAvailableDebitLimit: async ({}, clientId) => {
    try {
      const response = await ClientsService.getAvailableDebitLimit(clientId);

      return response.data;
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getAllSalesClient: async ({ commit }, { clientId, page, limit, way }) => {
    try {
      const response = await ClientsService.getAllSalesClient(
        clientId,
        page,
        limit,
        way
      );
      commit(types.LOAD_CLIENT_SALES, { sales: response.data.orders });
      // console.log(response)

      return response.data.sum;
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getAllSalesClientLength: async ({ commit }, { clientId }) => {
    try {
      const response = await ClientsService.getAllSalesClientLength(clientId);
      // console.log(response)
      // commit(types.LOAD_CLIENT, { client: response.data });
      return response.data;
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getClient: async ({ commit }, { id, companyId }) => {
    try {
      const response = await ClientsService.get(id, companyId);
      // commit(types.LOAD_CLIENT, { client: response.data });
      return response.data;
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getClients: async ({ commit }, { page, limit, order, way, search }) => {
    commit(types.SET_LOADING_CLIENTS, { isLoadingClients: true });

    try {
      const response = await ClientsService.getAll(
        page,
        limit,
        order,
        way,
        search,
      );
      commit(types.LIST_CLIENTS, { clients: response.data });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }

    commit(types.SET_LOADING_CLIENTS, { isLoadingClients: false });
  },
  getAllClients: async ({ commit }) => {
    const response = await ClientsService.getAllClients();
    commit(types.GET_ALL_CLIENTS, { clients: response.data.clients });
  },
  getDebtorsClients: async ({ commit }) => {
    const response = await clientsService.getAllDebtorsClients();
    commit(types.GET_ALL_DEBTORS_CLIENTS, { clients: response.data.clients });
  },
  getClientsTablesLength: async ({ commit }, { search }) => {
    try {
      const response = await ClientsService.getClientsLength(search);
      commit(types.TABLES_LENGTH, { clients: response.data });
    } catch (erro) {
      console.log(erro);
    }
  },
  getClientsDebitGroups: async ({ commit }) => {
    // commit(types.SET_LOADING_CLIENTS, { isLoadingClients: true });

    // try {
    const response = await ClientsDebitGroupsService.getAll();
    commit(types.LIST_CLIENT_DEBIT_GROUP, {
      clientsDebitGroups: response.data,
    });
    // } catch (erro) {
    // alert(erro);
    // commit(types.SETAR_ERRO, { erro });
    // }

    // commit(types.SET_LOADING_CLIENTS, { isLoadingClients: false });
  },
};
