import * as types from './mutation-types';

export default {
  [types.LIST_BALANCES]: (state, { balances }) => {
    state.balance = balances
  },
  [types.LIST_TODAY_SALES]: (state, { sales }) => {
    state.todaySales = sales
  },
  [types.LIST_LAST_SALES]: (state, { sales }) => {
    state.lastSalesByDay = sales
  },
}