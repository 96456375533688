import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/nfe/tributes-groups';

export default {
  getAll: () => apiClient.get(suffixEndpoint),
  create: data => {
    return apiClient.post(suffixEndpoint, data)
  },
  edit: data => {
    return apiClient.put(`${suffixEndpoint}/${data.id}`, data)
  },
  delete: id => {
    return apiClient.delete(`${suffixEndpoint}/${id}`)
  }
};
