import Vue from 'vue';
// import VueHtmlToPaper from 'vue-html-to-paper';
import Print from 'vue-print-nb'
// import myCustomCssStyles from '../assets/print.css';

// const options = {
//     name: '_blank',
//     specs: [
//         'fullscreen=yes',
//         'titlebar=yes',
//         'scrollbars=yes'
//     ],
//     styles: [
//         // "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
//         // './print.css'
//     ]
// }

Vue.use(Print);