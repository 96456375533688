import apiClient from '@/utils/api-client-without-base-url';


const suffixEndpoint = '/suppliers';

export default {
  getAll: () => apiClient.get(suffixEndpoint),
  get: id => {
    return apiClient.get(`${suffixEndpoint}/${id}`);
  },
  create: data => {
    return apiClient.post(suffixEndpoint, data)
  },
  registerImportedNf: data => {
    return apiClient.post(suffixEndpoint + "/import-nfe", data);
  },
  edit: data => {
    return apiClient.put(`${suffixEndpoint}/${data.id}`, data)
  },
  delete: id => {
    return apiClient.delete(`${suffixEndpoint}/${id}`)
  }
};
