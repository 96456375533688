import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/products';

export default {
  getAll: (
    page,
    limit,
    order,
    way,
    search,
    filterImage,
    filterStatus,
    filterCombo,
    all
  ) =>
    apiClient.get(`${suffixEndpoint}`, {
      params: {
        page,
        limit,
        order,
        way,
        search,
        filterImage,
        filterCombo,
        filterStatus,
        all,
      },
    }),
  getProductsLength: (search, filterImage, filterStatus, filterCombo) =>
    apiClient.get(`${suffixEndpoint}/tables-length`, {
      params: { search, filterImage, filterStatus, filterCombo },
    }),
  get: (productId) => {
    return apiClient.get(`${suffixEndpoint}/${productId}`);
  },
  getByName: (name, gtin) => {
    return apiClient.get(`${suffixEndpoint}/getByName`, { params: { name, gtin } });
  },
  create: (data) => {
    return apiClient.post(suffixEndpoint, data, {
      headers: {
        Accept: 'multipart/form-data',
      },
    });
  },
  convertNfXmlInJson: (data) => {
    return apiClient.post(suffixEndpoint + '/convert-nf-xml', data);
  },
  createArrayProducts: (products) => {
    return apiClient.post(`${suffixEndpoint}/array`, { products });
  },
  edit: (data) => {
    return apiClient.put(`${suffixEndpoint}/${data.get('id')}`, data, {
      headers: {
        Accept: 'multipart/form-data',
      },
    });
  },
  returnStock: (data) =>
    apiClient.put(
      suffixEndpoint + '/return-stock/' + `${data.productId}`,
      data
    ),
  simpleEdit: (data) => {
    return apiClient.put(`${suffixEndpoint}/simple-edit/${data.id}`, data);
  },
  delete: (id) => {
    return apiClient.delete(`${suffixEndpoint}/${id}`);
  },
  createReference: (data) => {
    return apiClient.post(`${suffixEndpoint}/references`, data);
  },
  deleteReference: (referenceId) => {
    return apiClient.delete(`${suffixEndpoint}/references/${referenceId}`);
  },
  setDefaultReference: (referenceId, productId) => {
    return apiClient.put(`${suffixEndpoint}/references/${referenceId}`, {
      referenceId,
      productId,
    });
  },
};
