export const EDIT_FINANCIAL_CATEGORIES = 'EDIT_FINANCIAL_CATEGORIES';
export const DELETE_FINANCIAL_CATEGORY = 'DELETE_FINANCIAL_CATEGORY';
export const CREATE_FINANCIAL_CATEGORIES = 'CREATE_FINANCIAL_CATEGORIES';
export const LIST_FINANCIAL_CATEGORIES = 'LIST_FINANCIAL_CATEGORIES';
export const UPSERT_FINANCIAL_CATEGORIES = 'UPSERT_FINANCIAL_CATEGORIES'
export const TABLES_TRANSACTIONS_LENGTH = 'TABLES_TRANSACTIONS_LENGTH'

export const CREATE_TRANSFER_BETWEEN_ACCOUNTS = 'CREATE_TRANSFER_BETWEEN_ACCOUNTS';

export const CREATE_FINANCIAL_TRANSACTION = 'CREATE_FINANCIAL_TRANSACTION';
export const EDIT_FINANCIAL_TRANSACTION = 'EDIT_FINANCIAL_TRANSACTION';
export const LIST_FINANCIAL_TRANSACTION = 'LIST_FINANCIAL_TRANSACTION';
export const DELETE_FINANCIAL_TRANSACTION = 'DELETE_FINANCIAL_TRANSACTION';

export const CREATE_DEVOLUTION = 'CREATE_DEVOLUTION';
export const LIST_DEVOLUTIONS = 'LIST_DEVOLUTIONS';
export const DECREASE_RETURNED_QUANTITY = 'DECREASE_RETURNED_QUANTITY'
