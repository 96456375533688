import ConfigurationService from './../services/configuration-service';
import PaymentMethodsService from './../services/payment-service';

import * as types from './mutation-types';

export default {
  certificateValidations: ({ commit }) => {
    const data = ConfigurationService.certificateValidations();
    return data;
  },
  removeCert: async ({ commit }) => {
    await ConfigurationService.removeCert();
    commit(types.DELETE_CERT)
  },
  uploadCert: async ({ commit }, certData) => {
    await ConfigurationService.uploadCert(certData);
    commit(types.UPLOAD_CERT, certData)
  },
  editConfig: async ({ commit }, { company }) => {
    const { data } = await ConfigurationService.edit(company);
    commit(types.EDIT_CONFIG, { company: data });
  },
  getPaymentMethods: async ({ commit }) => {
    const { data } = await PaymentMethodsService.getPaymentMethods();
    commit(types.GET_PAYMENT_METHODS, { paymentMethods: data });
  },
  createPaymentMethod: async ({ commit }, { paymentMethod }) => {
    const { data } = await PaymentMethodsService.createPaymentMethod(
      paymentMethod
    );
    commit(types.CREATE_PAYMENT_METHOD, { paymentMethod: data });
  },
  updatePaymentMethod: async ({ commit }, { paymentMethod }) => {
    const { data } = await PaymentMethodsService.updatePaymentMethod(
      paymentMethod
    );
    console.log(data);
    commit(types.EDIT_PAYMENT_METHOD, { paymentMethod: data[1] });
  },
  deletePaymentMethods: async ({ commit }, { paymentMethods }) => {
    await PaymentMethodsService.deletePaymentMethods(paymentMethods);
    commit(types.DELETE_PAYMENT_METHODS, { paymentMethods });
  },
  getCompany: async ({ commit }) => {
    const { data } = await ConfigurationService.get();
    commit(types.EDIT_CONFIG, { company: data });
  },
};
