import {
  CREATE_CARRIER,
  EDIT_CARRIER,
  DELETE_CARRIER,
  LIST_CARRIERS
} from './mutation-types';

export default {
  [CREATE_CARRIER]: (state, { carrier }) => {
    state.carriers.push(carrier);
  },
  [EDIT_CARRIER]: (state, { carrier }) => {
    const indice = state.carriers.findIndex(t => t.id === carrier.id);
    state.carriers.splice(indice, 1, carrier);
  },
  [DELETE_CARRIER]: (state, { carrier }) => {
    const indice = state.carriers.findIndex(t => t.id === carrier.id);

    if (indice >= 0) state.carriers.splice(indice, 1);
  },
  [LIST_CARRIERS]: (state, { carriers }) => {
    state.carriers = carriers;
  }
};
