import apiClient from '@/utils/api-client-without-base-url';


const suffixEndpoint = '/financial/transactions';

export default {
  create: (data) => apiClient.post(`${suffixEndpoint}`, data),
  update: (data) => apiClient.put(`${suffixEndpoint}/${data.id}`, data),
  getAll: (page, limit, way, date, type, accountId, categoriesId, search, all) => apiClient.get(`${suffixEndpoint}`, {params: {page, limit, way, date, type, accountId, categoriesId, search, all}}),
  getTransactionsLength: (search, date, type, accountId, categoriesId) => apiClient.get(`${suffixEndpoint}/tables-length`,  { params: { search, date, type, accountId, categoriesId }}),
  delete: (transactionId) => apiClient.delete(`${suffixEndpoint}/${transactionId}`),
  deleteByOrderId: (orderId) => apiClient.delete(`${suffixEndpoint}/by-order-id/${orderId}`),
  createTransferBetweenAccounts: data => apiClient.post(`${suffixEndpoint}/transfer-between-accounts`, data)
};
