<template>
  <div>
    <v-menu
      ref="menu"
      :disabled="disabled"
      v-model="menuDatePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
        :disabled="disabled"
          append-icon='la-calendar'
          :hide-details="true"
          :outlined="isOutlined"
          :value="value"
          @change="onChangeTextField"
          :label="label"
          v-on="on"
          v-bind="textFieldOptions"
          v-if="!inputHide && !icon"
        ></v-text-field>
        <v-btn :disabled="disabled" v-else-if="icon" v-on="getOn(on)" icon>
          <v-icon>la-calendar</v-icon>
        </v-btn>
        <span v-else></span>
      </template>
      <v-date-picker
      :disabled="disabled"
        ref="picker"
        locale="pt-BR"
        no-title
        :value="dateFormatEn"
        @input="change"
        v-bind="datePickerOptions"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    isOutlined:{
      type:Boolean,
      default:false
    },
    disabled:{
      default: false
    },
    label: {},
    value: {},
    textFieldOptions: {},
    datePickerOptions: {},
    isDatePickerVisible: false,
    inputHide: false,
    icon: {
      type: Boolean,
      default: false,
    },
    iconProps: {},
    iconEvents: {},
    format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
  },
  methods: {
    getOn(on) {
      console.log(on);
      return { ...on, ...(this.iconEvents || {}) };
    },
    change(event) {
      this.menuDatePicker = false;

      this.$emit('input', moment(event).format(this.format));
    },
    printOn(on) {
      console.log(on);
    },
    onChangeTextField(event) {
      let value = event;

      if (event && event.trim().length == 0) value = null;

      this.$emit('input', value);
    },
  },
  watch: {
    isDatePickerVisible(newValue) {
      if (this.menuDatePicker != newValue) {
        this.menuDatePicker = newValue;
      }
    },
    menuDatePicker(newValue) {
      if (this.isDatePickerVisible != newValue) {
        if (newValue) this.$emit('openMenu', event);
        else this.$emit('closeMenu', event);
      }
    },
    value(newValue) {
      if (newValue && newValue.trim().length > 0) {
        this.dateFormatEn = this.dateBrToEn(newValue);
      } else {
        this.dateFormatEn = null;
      }
    },
  },
  data() {
    return {
      menuDatePicker: false,
      dateFormatEn: '',
    };
  },
};
</script>