import { mensageTest } from '@/utils/helpers/testPermission'
import * as types from './mutation-types';

import service from "../services/financial-service"
import ordersService from "@/modules/orders/services/orders-service"


export default {
  getBalance: async ({ commit }, { date, account, type, tab }) => {
    const response = await service.getBalance(date, account, type, tab);
    commit(types.LIST_BALANCES, { balances: response.data });
  },
  getTodaySales: async ({ commit }) => {
    const response = await ordersService.getLastSales(1);
    commit(types.LIST_TODAY_SALES, { sales: response.data });
  },
  getSalesByDays: async ({ commit }, {date}) => {
    const response = await ordersService.getLastSales(date);
    commit(types.LIST_LAST_SALES, { sales: response.data });
  },
};
