import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/clients';

export default {
  getAll: (page, limit, order, way, search) =>
    apiClient.get(suffixEndpoint, {
      params: {
        page,
        limit,
        order,
        way,
        search,
      },
    }),
  get: (id, companyId) => {
    return apiClient.get(`${suffixEndpoint}/${id}/company/${companyId}`);
  },
  getAllClients: () => {
    return apiClient.get(`${suffixEndpoint}/getAll`);
  },
  create: (data) => {
    return apiClient.post(suffixEndpoint, data);
  },
  createBulkPerson: (data) => {
    return apiClient.post(`${suffixEndpoint}/create-bulk-person`, data);
  },
  getClientsLength: (search) =>
    apiClient.get(`${suffixEndpoint}/tables-length`, {
      params: { search },
    }),
  createBulkCompany: (data) => {
    return apiClient.post(`${suffixEndpoint}/create-bulk-company`, data);
  },
  edit: (data) => {
    return apiClient.put(`${suffixEndpoint}/${data.id}`, data);
  },
  delete: (id) => {
    return apiClient.delete(`${suffixEndpoint}/${id}`);
  },
  getAllSalesClientLength: (clientId) => {
    return apiClient.get(`${suffixEndpoint}/${clientId}/all-sales-length`);
  },
  getAllSalesClient: (clientId, page, limit, way) => {
    return apiClient.get(`${suffixEndpoint}/${clientId}/all-sales`, {
      params: { page, limit, way },
    });
  },
  getAvailableDebitLimit: (clientId) => {
    return apiClient.get(`${suffixEndpoint}/${clientId}/available-debit-limit`);
  },
  getAllDebtorsClients: () => {
    return apiClient.get(`${suffixEndpoint}/get-debtors-clients`)
  }
};
