import PdvService from '../../pdv/services/pdv-service';
import { mensageTest } from '@/utils/helpers/testPermission';
import * as types from './mutation-types';

import { mapOrders, mapOrder } from '@/modules/orders/utils/helpers/orders';

import moment from 'moment';

moment.locale('pt-br');

function getLastOpenedCashbox(cashBox) {
  let lastOpenedIndex = 0,
    balance = 0,
    openCashBoxDate,
    initialBalance = 0;
  for (let i = 0; i < cashBox.operations.length; i++) {
    const register = cashBox.operations[i];

    if (register.type == '1') {
      lastOpenedIndex = i;
      balance = register.value;
      openCashBoxDate = register.createdAt;
      initialBalance = register.value;
    }
  }

  return { lastOpenedIndex, balance, openCashBoxDate, initialBalance };
}

export default {
  createOperation: async ({ commit }, { operation }) => {
    const response = await PdvService.createCashboxOperation(operation);
    // console.log(response)
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.CREATE_OPERATION, { operation: response.data });
  },
  saveBalance: async ({ commit }, balance) => {
    try {
      await PdvService.saveCashBoxBalance(balance);
    } catch (e) {
      console.log(e);
    }
  },
  createCashBox: async ({ commit }, { cashBox }) => {
    const response = await PdvService.create(cashBox);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.CREATE_CASH_BOX, { cashBox: response.data });
  },
  getAllCashBoxes: async ({ commit }) => {
    try {
      const response = await PdvService.getAll();

      commit(types.LIST_CASH_BOXES, { cashBoxes: response.data });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getCashBoxByUserId: async ({ commit, rootState }) => {
    try {
      const response = await PdvService.getCashBoxByUserId(
        rootState.users.loggedUser.id
      );
      // console.log(response.data)
      commit(types.GET_LOGGED_CASH_BOX, { cashBox: response.data });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  cashBoxAmount: async ({ rootState }) => {
    try {
      const cashBox = rootState.pdv.loggedCashBox;
      const paymentMethods = rootState.config.paymentMethods;

      if (!cashBox) return null;

      let { balance, openCashBoxDate, initialBalance } =
        getLastOpenedCashbox(cashBox);

      for (let j = 0; j < cashBox.operations.length; j++) {
        const register = cashBox.operations[j];

        if (register.type == '2') balance += register.value;
        if (register.type == '3') balance -= register.value;
      }

      let addedBalance = 0,
        saleBalance = 0,
        subtractedBalance = 0;

      const indexes = [];

      const payments = paymentMethods.map((pay, index) => {
        indexes[pay.id] = index;

        return { ...pay, amount: 0 };
      });

      for (let j = 0; j < cashBox.operations.length; j++) {
        const register = cashBox.operations[j];
        payments.find((p) => {
          if (p.id == register.operationMethod) {
            if (register.type == '2') {
              if (register.isFromInstallment == false)
                addedBalance += register.value;
              else saleBalance += register.value;
              p.amount += register.value;
            }
            if (register.type == '3') {
              if (register.isFromInstallment == false)
                subtractedBalance += register.value;
              else saleBalance += register.value;
              p.amount -= register.value;
            }
          }
        });
      }

      const cashBoxOrders = await PdvService.getCashBoxLastOpenOrders(
        cashBox.id,
        [
          moment(openCashBoxDate).format('YYYY-MM-DD'),
          moment().add(1, 'days').format('YYYY-MM-DD'),
        ]
      );

      if (cashBoxOrders.data.length > 0) {
        for (const order of mapOrders(cashBoxOrders.data)) {
          if (
            moment(order.createdAt).isAfter(openCashBoxDate) &&
            order.statusId != 5
          ) {
            for (const pay of order.paymentMethods) {
              payments.find((p) => {
                if (p.id == pay.paymentCode) {
                  p.amount += pay.amountPaid - pay.changeTo;
                  if (
                    p.method != 'Crediário' &&
                    p.method != 'Crédito na loja' &&
                    p.method != 'Vale presente'
                  ) {
                    saleBalance += pay.amountPaid - pay.changeTo;
                    balance += pay.amountPaid - pay.changeTo;
                  }
                }
              });
            }
          }
        }
      }

      return {
        balance,
        balancesByPaymentMethods: payments,
        addedBalance,
        subtractedBalance,
        saleBalance,
        totalBalance: balance || 0,
        initialBalance: initialBalance,
      };
    } catch (err) {
      console.log(err);
    }
  },
  getCashBoxOperationsById: async (
    { commit },
    { id, page, limit, date, all }
  ) => {
    try {
      const response = await PdvService.getCashBoxOperations(
        id,
        page,
        limit,
        date,
        all
      );
      commit(types.LIST_CASH_BOX_OPERATIONS_BY_ID, {
        cashBoxId: id,
        operations: response.data,
      });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getCashBoxOperationsTableLength: async ({ commit }, { id, date }) => {
    try {
      const response = await PdvService.getCashBoxOperationsTableLength(
        id,
        date
      );
      // console.log(response)
      commit(types.CASH_BOX_OPERATIONS_TABLE_LENGTH, {
        tableLength: response.data,
      });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
};
