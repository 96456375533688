import moment from 'moment';

moment.locale("pt-br");

const mapCashBox = cashBox => {
  return {
    ...cashBox,
    createdAt: moment(cashBox.createdAt).format("DD/MM/YYYY HH:mm"),
    status: 'Fechado'
  };
};

const mapCashBoxes = cashBoxes => {
  return cashBoxes.map(cashBox => mapCashBox(cashBox));
};

export {
  mapCashBoxes,
  mapCashBox
};
