import Vue from 'vue';

const keyCodes = {
  v: 86,
  f1: 112,
  f2: 113,
  f3: 114,
  f4: 115,
  f5: 116,
  f6: 117,
  f7: 118,
  f8: 119,
  f9: 120,
  f10: 121,
  f11: 122,
  f12: 123,
  // camelCase won`t work
  mediaPlayPause: 179,
  // instead you can use kebab-case with double quotation marks
  'media-play-pause': 179,
};

Vue.config.keyCodes = keyCodes;

export default keyCodes;
