import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import promotionsActions from '../submodules/promotions/store/actions';
import promotionsGetters from '../submodules/promotions/store/getters';
import promotionsMutations from '../submodules/promotions/store/mutations';

import tributeGroupsActions from '../submodules/tributes-groups/store/actions';
import tributeGroupsGetters from '../submodules/tributes-groups/store/getters';
import tributeGroupsMutations from '../submodules/tributes-groups/store/mutations';

import supplierActions from '../submodules/suppliers/store/actions';
import supplierGetters from '../submodules/suppliers/store/getters';
import supplierMutations from '../submodules/suppliers/store/mutations';

import carrierActions from '../submodules/carriers/store/actions';
import carrierGetters from '../submodules/carriers/store/getters';
import carrierMutations from '../submodules/carriers/store/mutations';

import tagActions from './../submodules/tags/store/actions'
import tagGetters from './../submodules/tags/store/getters'
import tagMutations from './../submodules/tags/store/mutations'

const state = {
  erro: undefined,
  products: [],
  allProducts: [],
  categories: [],
  variations: [],
  promotions: [],
  suppliers: [],
  carriers: [],
  tags:[],
  amountSpentBoost: 0,
  boostedPromotions: [],
  tributesGroups: [],
  isLoadingProducts: false,
  ncmMap: null,
  cestMap: null,
  productsLength: 0
};

export default {
  namespaced: true,
  state,
  getters: {
    ...getters,
    ...promotionsGetters,
    ...supplierGetters,
    ...carrierGetters,
    ...tributeGroupsGetters,
    ...tagGetters,
  },
  mutations: {
    ...mutations,
    ...promotionsMutations,
    ...supplierMutations,
    ...carrierMutations,
    ...tributeGroupsMutations,
    ...tagMutations,
  },
  actions: {
    ...actions,
    ...promotionsActions,
    ...supplierActions,
    ...carrierActions,
    ...tributeGroupsActions,
    ...tagActions
  }
};
