<template>
  <v-dialog
    @click:outside="$emit('close', true)"
    v-model="isVisible"
    width="80%"
    max-width="500px"
  >
    <v-card>
      <v-card-title class="lighten-2"> {{ title }} </v-card-title>
      <v-card-text class="pl-8 pt-4"> </v-card-text>
      <v-card-actions>
        <v-btn
          color="normal"
          class="px-4"
          depressed
          @click="$emit('close', true)"
           id="specialButton"
          >Cancelar</v-btn
        >

        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="isLoading"
          :disabled="isLoading"
          class="px-8"
          depressed
          @click="onSubmit"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';

moment.locale('pt-br');

export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Deseja realmente excluir?',
    },
    paramsToDelete: {},
    storeActionName: {
      type: String,
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;

      try {
        // console.log(this.storeActionName);
        if (Array.isArray(this.paramsToDelete)) {
          for (let i = 0; i < this.paramsToDelete.length; i++) {
            let idToDelete = this.paramsToDelete[i];

            if (typeof this.paramsToDelete[i] == 'object') {
              idToDelete = this.paramsToDelete[i].id;
            }

            await this.$store.dispatch(
              this.storeActionName,
              idToDelete
            );
          }
        } else {
          await this.$store.dispatch(this.storeActionName, this.paramsToDelete);
        }

        this.$emit('success', true);
        this.$emit('close', true);
      } catch (err) {
        const error = this.getErrorMessageFromRequestError(err);

        this.$store.dispatch('showMessage', {
          message: error,
          color: 'normal',
        });
      }

      this.isLoading = false;
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>