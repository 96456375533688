import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/financial/bills';

export default {
  create: (data) => apiClient.post(`${suffixEndpoint}`, data),
  update: (data) => apiClient.put(`${suffixEndpoint}/${data.id}`, data),
  delete: (billId) => apiClient.delete(`${suffixEndpoint}/${billId}`),
  getAll: (page, limit, way, date, filterType, accountId, categoriesId, clientId, search, all, type) => apiClient.get(`${suffixEndpoint}`, {params: {page, limit, way, date, filterType, accountId, categoriesId, clientId, search, all, type}}),
  getBillsToPayLength: (search, date, filterType, accountId, categoriesId, clientId, type) => apiClient.get(`${suffixEndpoint}/tables-length`,  { params: { search, date, filterType, accountId, categoriesId, clientId, type }}),
  getAllRecurrenceTypes: () => apiClient.get(`${suffixEndpoint}/recurrence-types`),
  generateFinancialTransactionByBill: (data) => apiClient.post(`${suffixEndpoint}/generate-financial-transaction`, data),
  generateFinancialTransactionGroupByBill: (data) => apiClient.post(`${suffixEndpoint}/generate-financial-transactions-group`, data),
  // updateBillsValueByOrderId: (data) => apiClient.put(`${suffixEndpoint}/update-bill-value-by-order-id/${data.data.orderProduct.orderId}`, data)
};
