import { datetimeBr } from '@/utils/helpers/date';
import { currency } from '@/utils/helpers/currency';
import { maskBr } from 'js-brasil';

const mapFinancialTransaction = (transaction) => {
  return {
    ...transaction,
    client: transaction.supplier
      ? transaction.supplier.name
      : transaction.client
      ? transaction.client.name
      : '',
    account: transaction.account ? transaction.account.description : '',
    category: transaction.category ? transaction.category.description : '',
    formattedCreatedAt: datetimeBr(transaction.createdAt),
    value:
      transaction.type == 'Q' ? transaction.value : currency(transaction.value),
    ref: transaction,
  };
};

const mapFinancialTransactions = (array) => {
  return array.map((transaction) => mapFinancialTransaction(transaction));
};

export { mapFinancialTransactions, mapFinancialTransaction };
