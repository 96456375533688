<template>
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    v-bind="$attrs"
  >
    <defs>
      <clipPath>
        <rect
          class="a"
          width="23"
          height="24"
          transform="translate(1606 190.743)"
        />
      </clipPath>
    </defs>
    <g
      class="b"
      transform="translate(-1606 -190.743)"
    >
      <g transform="translate(1606 191.349)">
        <path
          class="c"
          d="M.8,6.965a.8.8,0,0,0,.8-.8V2.741L6.774,7.909A.8.8,0,0,0,7.909,6.774L2.74,1.605H6.163A.8.8,0,0,0,6.163,0H.8A.8.8,0,0,0,0,.8v5.36A.8.8,0,0,0,.8,6.965Z"
          transform="translate(0 0)"
        />
        <path
          class="c"
          d="M28.61,22.448a.8.8,0,0,0-.8.8v3.422l-5.328-5.328a.8.8,0,1,0-1.135,1.135l5.328,5.328H23.251a.8.8,0,0,0,0,1.605h5.36a.8.8,0,0,0,.8-.8V23.25A.8.8,0,0,0,28.61,22.448Z"
          transform="translate(-6.368 -6.368)"
        />
        <path
          class="c"
          d="M6.934,21.344,1.606,26.672V23.25A.8.8,0,0,0,0,23.25v5.36a.8.8,0,0,0,.8.8H6.163a.8.8,0,0,0,0-1.605H2.741l5.328-5.328a.8.8,0,0,0-1.135-1.135Z"
          transform="translate(0 -6.368)"
        />
        <path
          class="c"
          d="M28.679,0H23.32a.8.8,0,1,0,0,1.605h3.422L21.573,6.774a.8.8,0,0,0,1.135,1.135L27.877,2.74V6.163a.8.8,0,0,0,1.605,0V.8A.8.8,0,0,0,28.679,0Z"
          transform="translate(-6.437 0)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ExpandIcon'
};
</script>

<style scoped>
.a {
  fill: #fff;
  stroke: #707070;
}
.b {
  clip-path: url(#a);
}
.c {
  fill: #999;
}
</style>
