import { render, staticRenderFns } from "./ButtonPrimary.vue?vue&type=template&id=813a8fd4&scoped=true&"
var script = {}
import style0 from "./ButtonPrimary.vue?vue&type=style&index=0&id=813a8fd4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "813a8fd4",
  null
  
)

export default component.exports