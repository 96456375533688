import moment from 'moment';

export const datetimeBr = (datetime, format = 'DD/MM/YYYY HH:mm') => {
  return moment(datetime).format(format);
};

export const dateBrToEn = (data) =>  {
  var dia = data.split('/')[0];
  var mes = data.split('/')[1];
  var ano = data.split('/')[2];

  return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2);
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
};

export const datetimeBrToEn = (dataParam) =>  {
  const [data, hour] = dataParam.split(" ");

  var dia = data.split('/')[0];
  var mes = data.split('/')[1];
  var ano = data.split('/')[2];

  return ano + '-' + ('0' + mes).slice(-2) + '-' + ('0' + dia).slice(-2) + " " + hour;
  // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
};