import apiClient from '@/utils/api-client';

const suffixEndpoint = '/payments';

export default {
  getAcquirers: () => {
    return apiClient.get(`${suffixEndpoint}/acquirers`);
  },
  getPaymentMethods: () => {
    return apiClient.get(`${suffixEndpoint}/payment-methods`);
  },
  createPaymentMethod: (paymentMethod) => {
    return apiClient.post(`${suffixEndpoint}/payment-methods/create`, {
      paymentMethod,
    });
  },
  updatePaymentMethod: (paymentMethod) => {
    return apiClient.put(`${suffixEndpoint}/payment-methods/update`, {
      paymentMethod,
    });
  },
  deletePaymentMethods: (paymentMethods) => {
    return apiClient.post(`${suffixEndpoint}/payment-methods/delete`, {
      paymentMethods,
    });
  },
};
