<template>
  <v-app>
    <v-main :class="{
      'content-padding':
        isAppMenuVisible &&
        $route.path !== '/login' &&
        !isMobileTest(),
      'content-padding-mobile': isAppMenuVisible && isMobileTest() != false,
    }">
      <keep-alive ref="keep" include="page" v-if="isLoggedIn">
        <!-- <router-view /> -->
        <AppLayout v-if="loggedUser && loggedUser.id">
          <template>
            <keep-alive v-if="!($route.path == '/pdv')">
              <router-view></router-view>
            </keep-alive>
            <router-view v-if="$route.path == '/pdv'"></router-view>
          </template>
        </AppLayout>
      </keep-alive>
      <router-view v-else></router-view>
    </v-main>

    <v-snackbar app bottom @input="(e) => setIsSnackbarVisible({ isVisible: e })" :value="isSnackbarVisible"
      :color="snackbarColor">
      <div class="tw-flex tw-justify-between tw-items-center">
        <span>{{ snackbarMessage }}</span>
        <v-btn icon @click="setIsSnackbarVisible({ isVisible: false })">
          <v-icon :color="snackbarColor == 'primary' ? 'white' : 'red'">la-close</v-icon>
        </v-btn>
      </div>
    </v-snackbar>
    <!-- <audio id="player" muted loop>
      <source src="./modules/orders/store/sound-notification.mp3" type="audio/mp3" />
    </audio> -->
  </v-app>
</template>

<script>
import registerOrderStore from './modules/orders/store/register';
import registerProductStore from './modules/products/store/register';
import registerClientStore from './modules/clients/store/register';
import registerPdvStore from './modules/financial/store/register';
import registerUsersStore from './modules/configurations/submodules/users/store/register';
import registerConfigStore from './modules/configurations/store/register';
import registerOrderRequestStore from './modules/ordersRequest/store/register'

import {
  createNamespacedHelpers,
  mapState,
  mapActions as mapActionsVuex,
} from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('orders');
const { mapActions: mapActionsProducts } = createNamespacedHelpers('products');
const { mapActions: mapActionsClients } = createNamespacedHelpers('clients');
const { mapActions: mapActionsPdv } = createNamespacedHelpers('pdv');
const { mapActions: mapActionsOrdersRequest } = createNamespacedHelpers('ordersRequest')
const { mapActions: mapActionsUsers, mapState: mapStateUser } =
  createNamespacedHelpers('users');
const { mapActions: mapActionsConfig } = createNamespacedHelpers('config');

export default {
  name: 'App',
  data() {
    return {
      isLoggedIn: false, // determines if dashboard is kept alive or not
      isRouterViewVisible: true,
    };
  },
  computed: {
    ...mapGetters(['orders']),
    ...mapState([
      'isAppMenuVisible',
      'isSnackbarVisible',
      'snackbarMessage',
      'snackbarColor',
    ]),
    ...mapStateUser(['loggedUser']),
  },
  methods: {
    ...mapActionsVuex(['setIsSnackbarVisible']),
    ...mapActionsOrdersRequest(['getTags', 'getOrderRequests']),
    ...mapActions(['getOrders', 'getOrdersMessages', 'getInutilizedFiscalInvoices']),
    ...mapActionsVuex(['setIsSnackbarVisible']),
    ...mapActionsProducts([
      'getProducts',
      'getSuppliers',
      'getCarriers',
      'getCategories',
      'getAllTributesGroups',
      'getTagModels',
    ]),
    ...mapActionsClients(['getClients', 'getClientsDebitGroups']),
    ...mapActionsPdv([
      'getCashBoxByUserId',
      'getAllCashBoxes',
      'getAllFinancialAccounts',
      'getFinancialTransactions',
      'getAllFinancialCategories',
      'getAllBills',
      'getAllRecurrenceTypes',
      'getAllDevolutions',
    ]),
    ...mapActionsUsers(['getUsers', 'getVendors', 'getUserRoles', 'loadLoggedUser']),
    ...mapActionsConfig(['getCompany', 'getPaymentMethods']),
  },
  watch: {
    isLoggedIn(val) {
      if (val == true) {
        registerOrderStore(this.$store);
        registerProductStore(this.$store);
        registerClientStore(this.$store);
        registerPdvStore(this.$store);
        registerUsersStore(this.$store);
        registerConfigStore(this.$store);
        registerOrderRequestStore(this.$store)

        // this.loadLoggedUser();
        Promise.all([
          this.getCashBoxByUserId(),
          this.getAllRecurrenceTypes(),
          this.getTags(),
          this.getAllFinancialAccounts(),
          this.getAllFinancialCategories(),
          this.getUsers(),
          this.getVendors(),
          this.getUserRoles(),
          this.getAllTributesGroups(),
          this.getCarriers(),
          this.getTagModels(),
          this.getSuppliers(),
          this.getCompany(),
          this.getPaymentMethods(),
          this.getCategories(),
          this.getOrderRequests(),
          this.getInutilizedFiscalInvoices()
        ])
      }
    },
    $route(to, from) {
      // console.log(this.$route.path);
      const companyId = window.localStorage.getItem('companyId');

      if (companyId === null) {
        this.isLoggedIn = false;
      } else {
        this.isLoggedIn = true;
      }
    },
  },
};
</script>

<style>
.content-scroll {
  height: calc(100vh - 73px);
  overflow-y: auto;
  overflow-x: hidden;
}

.content-scroll.fill-height {
  height: 100%;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: #c9c9c9;
}

*::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

*::-webkit-scrollbar-thumb {
  /* background-color: #f0f0f0; */
  /* background-color: #c9c9c9; */
  background-color: #e9e9e9;
  border-radius: 30px;
}

.content-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.content-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.content-scroll::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 30px;
}

.custom-scroll {
  /* Esconder Scroll */
  /* overflow-y: auto; */
  overflow-x: hidden;
}

.light-scroll {
  overflow: auto;
}

.light-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.light-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.light-scroll::-webkit-scrollbar-thumb {
  /* background-color: #f0f0f0; */
  background-color: #efefef !important;
  border-radius: 30px;
}

.custom-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  /* background-color: #f0f0f0; */
  background-color: #c9c9c9;
  border-radius: 30px;
}

.v-data-table--fixed-header:not(.light-scroll)>.v-data-table__wrapper::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.v-data-table--fixed-header:not(.light-scroll)>.v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.v-data-table--fixed-header:not(.light-scroll)>.v-data-table__wrapper::-webkit-scrollbar-thumb {
  /* background-color: #f0f0f0; */
  background-color: #c9c9c9;
  border-radius: 30px;
}

.table-custom-scroll .v-data-table__wrapper::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.table-custom-scroll .v-data-table__wrapper::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.table-custom-scroll .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 30px;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

html {
  overflow: hidden !important;
}

.bb {
  border-bottom: 1px solid var(--v-border-base);
}

.bt {
  border-top: 1px solid var(--v-border-base);
}

.no-spacing {
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.v-icon.v-icon.no-focus::after {
  display: none;
}

.hover-pointer:hover {
  cursor: pointer !important;
}

.no-border {
  border: none !important;
}

.no-border-and-box-shadow {
  box-shadow: none !important;
  border: none !important;
}

.v-application {
  font-family: 'poppins', sans-serif !important;
}

.content-padding {
  padding: 0px 0px 0px 88px !important;
}

.content-padding-mobile {
  padding: 0px 0px 0px 56px !important;
}

.mobile-padding {
  padding: 0px 20px 0px !important;
}

.mobile-padding-2 {
  padding: 0px 40px 0px !important;
}

.title-padding {
  padding: 20px 15px 0px !important;
}

/* .display-1,
.display-2,
.display-3,
.display-4,
.headline,
.title,
.subtitle-1,
.subtitle-2,
.body-1,
.body-2,
.caption,
.overline {
  font-family: 'poppins', 'sans-serif'!important;
} */
/* :not(i)  {
  font-family: 'poppins', sans-serif !important;
} */
.v-simple-checkbox .v-icon,
.check-mini .v-icon,
table .v-icon:not(.small) {
  font-size: 20px !important;
}

.v-data-table .mdi-checkbox-marked {
  color: var(--v-primary-base) !important;
}

/* .mdi-minus-box:before {
  content: '\F0131' !important;
} */

.v-btn {
  letter-spacing: 0 !important;
  text-transform: inherit !important;
}

.v-application a {
  color: inherit !important;
  text-decoration: inherit !important;
}

[relative] {
  position: relative;
}

[absolute] {
  position: absolute !important;
}

[borderless] {
  border: none;
}

.green2--text {
  color: #00d473;
}

.flex-wrap {
  flex-wrap: wrap;
}

.break-word {
  word-break: break-word;
}

.w-full {
  width: 100%;
}

.font-regular {
  font-weight: 500;
}

.chart-container {
  width: 100%;
  min-height: 250px;
  position: relative;
}

@media (max-width: 768px) {
  .chart-container {
    min-height: 300px;
  }
}

.bg-primary {
  background: var(--v-primary-base);
}

.bg-gray {
  background: var(--v-gray-base);
}

.bg-extra-light-gray {
  background: var(--v-extraLightGray-base);
}


.v-menu__content {
  border: 1px solid var(--v-border-base) !important;
  box-shadow: none !important;
}
</style>
