<template>
  <div>
    <v-sheet class="d-flex align-center tw-justify-center" :class="{ 'transparent': isTransparent, 'pa-3': !noPadding }"
      relative @click.stop>
      <label v-if="label">{{ label }}</label>
      <span v-if="text" class="pl-1">{{ text }}</span>
      <div class="d-flex align-center tw-justify-center" v-if="input">
        <v-text-field :value="value" class="ml-2" style="width:50px" v-bind="textFieldOptions"
          @keyup="$emit('keyup', $event)" type="number" @input="inputQuantity" @change="changeQuantity">
        </v-text-field>
        <span class="ml-2">{{ unit }}</span>
      </div>
      <InputNumber v-if="number" :value="value" :max="max" :isArrowsDisabled="isArrowsDisabled"
        :allowNegativeNumbers="allowNegativeNumbers" :style="text ? 'margin-left: 10px' : ''"
        :textFieldOptions="textFieldOptions" @input="$emit('input', $event)" />
    </v-sheet>
  </div>
</template>

<script>
export default {
  methods:{
    changeQuantity(quantity) {
      if(this.input)
       quantity = parseFloat(quantity)
      this.$emit('change', quantity)
    },
    inputQuantity(quantity) {
      if(this.input)
       quantity = parseFloat(quantity)
      this.$emit('input', quantity)
    },
  },
  props: {
    input: {
      type: Boolean,
      default: false
    },
    allowNegativeNumbers: {
      default: false
    },
    noPadding: {
      default: false,
    },
    number: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      required: false,
      default: null
    },
    isTransparent: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'success'
    },
    isArrowsDisabled: {
      type: Boolean,
      default: false
    },
    unit: {
      type: String,
      default: ''
    },
    text: {},
    label: {},
    textFieldOptions: {},
    value: {},
    lineBorder: {}
  }
};
</script>

<style scoped>
.v-sheet {
  background: var(--v-backgroundSecondary-base);
}

.transparent {
  background-color: 'transparent';
}
</style>
