<template>
  <div class="d-flex flex-column">
    <v-card outlined class="d-flex tw-rounded-sm pa-2 mb-3 hover-pointer" v-for="(button, index) in items" :key="index"
      @click="(e) => onClick(index)">
      <v-icon size="32">{{ button.icon }}</v-icon>
      <div class="ml-3 d-flex flex-column justify-content-between">
        <span class="tw-font-semibold">{{
          button.title
        }}</span>
        <span>{{ button.description }}</span>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    items: Array,
  },
  methods: {
    onClick(index) {
      const item = this.items[index];

      if (item.onClick) {
        item.onClick();
      }

      this.$emit('onClick', {
        item,
        index,
      });
    },
  },
};
</script>