import supplierRoutes from './../submodules/suppliers/router';
import carriersRoutes from './../submodules/carriers/router';

const Container = () => import('./../views/Container');

// const ProductPageDetails = () => import('./../views/ProductPageDetails');
const ProductPageList = () => import('./../views/ProductPageList');
// const ProductFormTaxDataEdit = () => import('./../forms/ProductFormTaxDataEdit');

export default [
  {
    path: '/produtos',
    component: Container,
    meta: { requiresAuth: true, permissions: ['view_products'] },
    children: [
      {
        path: '',
        name: 'product-list',
        component: ProductPageList
      },
      ...supplierRoutes,
      ...carriersRoutes,
      // {
      //   path: 'produto/:id/',
      //   name: 'product-details',
      //   component: ProductPageDetails
      // },
      // {
      //   path: 'produto/:id/dados-fiscais',
      //   name: 'product-tax-data-edit',
      //   component: ProductFormTaxDataEdit
      // },
    ]
  }
];
