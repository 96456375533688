import BillService from '../services/bills-service';
import { mensageTest } from '@/utils/helpers/testPermission';
import * as types from './mutation-types';
import * as typesTransaction from '../../financial-transactions/store/mutation-types';

export default {
  createBill: async ({ commit }, { bill }) => {
    const response = await BillService.create(bill);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.CREATE_BILL, {
      bill: response.data,
    });

    return response.data;
  },
  updateBill: async ({ commit }, { bill }) => {
    const response = await BillService.update(bill);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_BILLS, {
      bills: response.data,
    });
  },
  deleteBill: async ({ commit }, { billId }) => {
    try {
      let response = await BillService.delete(billId);
      if (response.status == 405) {
        // permission error
        mensageTest(response);
      }
      if (response.status >= 200 && response.status < 300) {
        commit(types.DELETE_BILL, {
          billId,
        });
      }
      return response.status;
    } catch (err) {
      return err;
    }
  },
  generateFinancialTransactionGroupByBill: async ({ commit }, payload) => {
    const response = await BillService.generateFinancialTransactionGroupByBill(
      payload
    );
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    for (const bill of response.data.updatedBills) {
      commit(types.EDIT_BILL, {
        bill,
      });
    }
    
    for (const financialTransaction of response.data
      .createdFinancialTransactions) {
      commit(typesTransaction.CREATE_FINANCIAL_TRANSACTION, {
        financialTransaction,
      });
    }
  },
  generateFinancialTransactionByBill: async (
    { commit },
    { financialTransaction }
  ) => {
    const response = await BillService.generateFinancialTransactionByBill(
      financialTransaction
    );
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_BILL, {
      bill: response.data.bill,
    });

    commit(typesTransaction.CREATE_FINANCIAL_TRANSACTION, {
      financialTransaction: response.data.financialTransaction,
    });
  },
  getAllRecurrenceTypes: async ({ commit }) => {
    const responseRecurrence = await BillService.getAllRecurrenceTypes();

    commit(types.LOAD_RECURRENCE_TYPES, {
      recurrenceTypes: responseRecurrence.data,
    });
  },
  getBillsToRecieve: async ({ commit }, {page, limit, way, date, filterType, accountId, categoriesId, clientId, search, all, type}) => {
    const response = await BillService.getAll(page, limit, way, date, filterType, accountId, categoriesId, clientId, search, all, type);
    commit(types.LIST_BILLS_TO_RECIEVE, {
      bills: response.data.data
    });
    return response.data.sum
  },
  getBillsToRecieveTablesLength: async ({ commit }, {search, date, filterType, accountId, categoriesId, clientId, type}) => {
    const response = await BillService.getBillsToPayLength(search, date, filterType, accountId, categoriesId, clientId, type);
    commit(types.TABLES_BILLS_TO_RECIEVE_LENGTH, { bills: response.data });
  },
  getBillsToPay: async ({ commit }, {page, limit, way, date, filterType, accountId, categoriesId, clientId, search, all, type}) => { 
    const response = await BillService.getAll(page, limit, way, date, filterType, accountId, categoriesId, clientId, search, all, type)
    commit(types.LIST_BILLS_TO_PAY, {
      bills: response.data.data
    });
  },
  getBillsToPayTablesLength: async ({ commit }, {search, date, filterType, accountId, categoriesId, clientId, type}) => {
    const response = await BillService.getBillsToPayLength(search, date, filterType, accountId, categoriesId, clientId, type);
    commit(types.TABLES_BILLS_TO_PAY_LENGTH, { bills: response.data });
  },
  getAllBills: async ({ commit }) => {
    const response = await BillService.getAll();
    
    // const responseRecurrence = await BillService.getAllRecurrenceTypes();

    // commit(types.LOAD_RECURRENCE_TYPES, {
    //   recurrenceTypes: responseRecurrence.data
    // });

    commit(types.LIST_BILL, {
      bills: response.data,
    });
  },
};
