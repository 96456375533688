import { mapBills, isPaidBill } from '../utils/helpers';

import moment from 'moment';
moment.locale('pt-br');

const isBetweenDates = (dateToVerify, start, end) => {
  return (
    moment(dateToVerify).isSameOrAfter(moment(start).startOf('day')) &&
    moment(dateToVerify).isSameOrBefore(moment(end).endOf('day'))
  );
};

export default {
  bills: state => mapBills(state.bills),
  billsToPay: state => mapBills(state.billsToPay),
  billsToReceive: state => mapBills(state.billsToRecieve),
  // billsToReceive: state =>
  //   mapBills(state.bills.filter(({ type }) => type == 'E')),
  // billsByOrderId: state => selectedOrderId =>
  //   (state.bills.filter(({ orderId }) => orderId == selectedOrderId)),
  // billsToPayAmount: state =>
  //   state.bills
  //     .filter(b => {
  //       const { type } = b;
  //       return type == 'S' && !isPaidBill(b);
  //     })
  //     .reduce((accum, current) => accum + current.value, 0),
  // billsToReceiveAmount: state =>
  //   state.bills
  //     .filter(b => {
  //       const { type } = b;
  //       return type == 'E' && !isPaidBill(b) && b.value.toFixed(2) > 0;
  //     })
  //     .reduce((accum, current) => accum + current.value, 0),

  // getBillsToReceiveAmountByAccount: state => accountIdParam =>
  //   state.bills
  //     .filter(b => {
  //       const { type, accountId } = b;
  //       return type == 'E' && !isPaidBill(b) && accountId == accountIdParam;
  //     })
  //     .reduce((accum, current) => accum + current.value, 0),

  // getBillsToPayAmountByAccount: state => accountIdParam =>
  //   state.bills
  //     .filter(b => {
  //       const { type, accountId } = b;

  //       return type == 'S' && !isPaidBill(b) && accountId == accountIdParam;
  //     })
  //     .reduce((accum, current) => accum + current.value, 0),
  // outstandingPayables: state =>
  //   mapBills(
  //     state.bills.filter(b => {
  //       return b.type == 'S' && !isPaidBill(b);
  //     })
  //   ),
  pendingBills: state =>
    mapBills(
      state.bills.filter(t => {
        return !isPaidBill(t);
      })
    ),
  overdueBills: state =>
    mapBills(
      state.bills.filter(t => {
        return (
          t.type == 'S' &&
          !isPaidBill(t) &&
          t.expirationDate &&
          moment().subtract(1, 'days').isAfter(moment(t.expirationDate))
        );
      })
    ),
    overdueReciveBills: state =>
    mapBills(
      state.bills.filter(t => {
        return (
          t.type == 'E' &&
          !isPaidBill(t) &&
          t.expirationDate &&
          moment().subtract(1, 'days').isAfter(moment(t.expirationDate))
        );
      })
    ),
  // paidBills: state =>
  //   mapBills(
  //     state.bills.filter(t => {
  //       return t.type == 'S' && isPaidBill(t);
  //     })
  //   ),
  // filterBillsByClient: state => ({
  //   clientId,
  // }) => {
  //   let clientBills = mapBills(state.bills.filter((bill) => {
  //     return bill.clientId == clientId
  //   })
  //   )
  //   return clientBills
  // },
  // paidBillsByClient: state => ({
  //   clientId,
  // }) => {
  //   let clientPaidBills = mapBills(
  //     state.bills.filter(t => {
  //       return t.clientId == clientId && isPaidBill(t);
  //     })
  //   )
  //   return clientPaidBills
  // },
  // pendingBillsbyClient: state => ({
  //   clientId,
  // }) => {
  //   let clientPaidBills = mapBills(
  //     state.bills.filter(t => {
  //       return t.clientId == clientId && !isPaidBill(t);
  //     })
  //   )
  //   return clientPaidBills
  // },
  // overdueBillsByClient: state => ({
  //   clientId,
  // }) => {
  //   let clientOverdueBills = mapBills(
  //     state.bills.filter(t => {
  //       return (
  //         t.clientId == clientId &&
  //         !isPaidBill(t) &&
  //         t.expirationDate &&
  //         moment().isAfter(moment(t.expirationDate))
  //       );
  //     })
  //   )
  //   return clientOverdueBills
  // },
  // filterBillsByAccount: state => ({
  //   accountId,
  //   bills = state.bills,
  //   datesToFilter = [],
  //   onlyPending = false,
  //   onlyPaid = false,
  //   type,
  //   mask = true
  // }) => {
  //   let array = bills;

  //   if (mask) array = mapBills(bills);

  //   return array.filter(t => {
  //     if (onlyPending && isPaidBill(mask ? t.ref : t)) return false;
  //     if (onlyPaid && !isPaidBill(mask ? t.ref : t)) return false;

  //     if (datesToFilter && datesToFilter.length == 2) {
  //       if (
  //         !isBetweenDates(
  //           mask ? t.ref.expirationDate : t.expirationDate,
  //           datesToFilter[0],
  //           datesToFilter[1]
  //         )
  //       )
  //         return false;
  //     }

  //     if (type && type == 'E' && t.type != 'E') return false;
  //     if (type && type == 'S' && t.type != 'S') return false;

  //     return t.accountId == accountId || accountId == null;
  //   });
  // },

  // getBillsToReceiveBalance: state => (accountId, datesToFilter = []) => {
  //   let balance = 0;

  //   const transactions = state.bills.filter(t => {
  //     if (isPaidBill(t)) return false;

  //     if (datesToFilter && datesToFilter.length == 2) {
  //       if (
  //         !isBetweenDates(t.expirationDate, datesToFilter[0], datesToFilter[1])
  //       )
  //         return false;
  //     }

  //     if ((t.accountId == accountId || accountId == null) && t.value.toFixed(2) > 0) {
  //       let paidValue = t.financialTransactions.length > 0 ? t.financialTransactions.reduce(
  //         (accum, current) => accum + current.value,
  //         0) : 0 
  //       if (t.type == 'E') balance += t.value - paidValue;
  //     }

  //     return t.accountId == accountId;
  //   });

  //   return balance;
  // },

  // getBillsToPayBalance: state => (accountId, datesToFilter = []) => {
  //   let balance = 0;

  //   const transactions = state.bills.filter(t => {
  //     if (isPaidBill(t)) return false;

  //     if (datesToFilter && datesToFilter.length == 2) {
  //       if (
  //         !isBetweenDates(t.expirationDate, datesToFilter[0], datesToFilter[1])
  //       )
  //         return false;
  //     }

  //     if (t.accountId == accountId || accountId == null) {
  //       let paidValue = t.financialTransactions.length > 0 ? t.financialTransactions.reduce(
  //         (accum, current) => accum + current.value,
  //         0) : 0 
  //       if (t.type == 'S') balance += t.value - paidValue;
  //     }

  //     return t.accountId == accountId;
  //   });

  //   return balance;
  // }
};
