<template>
  <v-data-table :headers="headers" :items="items" :search="search" :show-select="showSelect" hide-default-footer
    :items-per-page="itemsPerPage" class="table-hover-pointer striped" no-data-text="Sem dados"
    no-results-text="Nenhum resultado encontrado" @page-count="$emit('page-count', $event)"
    @click:row="$emit('click:row', $event)" @input="$emit('input', $event)"
    @item-selected="$emit('item-selected', $event)" @toggle-select-all="$emit('toggle-select-all', $event)"
    :mobile-breakpoint="0" v-bind="$attrs" :custom-filter="customFilter ?? defaultFilter">
    <template v-slot:item.data-table-select="{ isSelected, select }">
      <v-simple-checkbox color="primary" @click.stop :value="isSelected" @input="select($event)"></v-simple-checkbox>
    </template>
    <template v-slot:[slotName]="{ item }" v-if="slotName">
      <slot name="item" :item="item">
        <div style="width: 100%" v-html="slotRender(item)"></div>
      </slot>
    </template>
    <template v-slot:item.totalDebit="{ item }">
      <span v-show="item.totalDebit > 0" class="red--text font-regular mr-1">
        - {{ currencyBr(item.totalDebit) }}
      </span>
    </template>
    <template v-slot:item.futureCredit="{ item }">
      <span v-if="item.futureCredit > 0" class="green--text font-regular mr-1">
        + {{ currencyBr(item.futureCredit.toFixed(2)) }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import { resizableGrid } from '@/plugins/resizable-table';
import moment from 'moment';
export default {
  name: 'DataTable',
  props: {
    headers: Array,
    items: Array,
    search: {
      type: String,
      default: '',
    },
    slotName: String,
    slotRender: Function,
    showSelect: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Number,
      default: null,
    },
    customFilter: {
      type: Function,
      default: null,
    }
  },
  mounted() {
    var tables = document.querySelectorAll('.resizable-table table');

    for (var i = 0; i < tables.length; i++) {
      resizableGrid(tables[i]);
    }
  },
  methods: {
    defaultFilter(value, search, item) {
      return value != null &&
        search != null &&
        typeof value !== 'boolean' &&
        value.toString().toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
    }
  }
};
</script>

<style>
.v-data-table .v-simple-checkbox .v-icon.mdi-checkbox-blank-outline {
  opacity: 0;
}

.v-data-table tr:hover .v-simple-checkbox .v-icon {
  opacity: 1;
}

@media (max-width: 768px) {
  .v-data-table .v-simple-checkbox .v-icon.mdi-checkbox-blank-outline {
    opacity: 1;
  }

  /*.v-simple-checkbox .v-icon, .check-mini .v-icon, table .v-icon{
    font-size: 25px !important;
  }*/
}

.v-data-table:not(.not-hover) tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.not-clickable) {
  cursor: pointer;
}
</style>
