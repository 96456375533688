export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const LIST_ALL_PRODUCTS = 'LIST_ALL_PRODUCTS'
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const SET_LOADING_PRODUCTS = 'SET_LOADING_PRODUCTS';
export const TABLES_LENGTH = 'TABLES_LENGTH'
export const PUSH_PRODUCTS = 'PUSH_PRODUCTS'

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const LIST_CATEGORIES = 'LIST_CATEGORIES';

export const CREATE_REFERENCE = 'CREATE_REFERENCE';
export const EDIT_REFERENCE = 'EDIT_REFERENCE';
export const DELETE_REFERENCE = 'DELETE_REFERENCE';
export const SET_DEFAULT_REFERENCE = 'SET_DEFAULT_REFERENCE';

export const CREATE_SUBCATEGORY = 'CREATE_SUBCATEGORY';
export const EDIT_SUBCATEGORY = 'EDIT_SUBCATEGORY';
export const DELETE_SUBCATEGORY = 'DELETE_SUBCATEGORY';

export const CREATE_VARIATION = 'CREATE_VARIATION';
export const EDIT_VARIATION = 'EDIT_VARIATION';
export const DELETE_VARIATION = 'DELETE_VARIATION';
export const LIST_VARIATIONS = 'LIST_VARIATIONS';

export const CREATE_OPTION = 'CREATE_OPTION';
export const EDIT_OPTION = 'EDIT_OPTION';
export const DELETE_OPTION = 'DELETE_OPTION';

// export const SET_NCM = 'SET_NCM'
