<template>
  <v-menu v-model="menu" :close-on-content-click="false" transition="slide-x-transition" content-class="lite-menu"
    :nudge-width="250" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <div class="user-card" v-bind="attrs" v-on="on">{{ firstLetters }}</div>
    </template>

    <v-card>
      <v-card-text class="py-5 px-5">
        <div class="d-flex justify-space-between">
          <div class="d-flex flex-column mr-5">
            <span class="title">{{ loggedUser.name }}</span>
            <span>{{ loggedUser.role.name }}</span>
            <span>{{ loggedUser.email }}</span>
          </div>
          <div class="tw-flex tw-flex-col">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click.stop="dialog = true">
                  <v-icon>mdi-logout</v-icon>
                </v-btn>

                <v-dialog v-model="dialog" max-width="420">
                  <v-card>
                    <v-card-title class="headline">Sair mesmo da conta?</v-card-title>
                    <v-card-actions>
                      <v-btn color="black darken-1" text @click="dialog = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" class="px-8" depressed @click="logout">Sair</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <span>Sair da conta</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mt-2" icon v-bind="attrs" v-on="on" href="https://wa.me/555430110010" target="_blank">
                    <v-icon>la-headset </v-icon>
                  </v-btn>
              </template>
              <span>Suporte</span>
            </v-tooltip>
          </div>
        </div>
        <div class="mt-5 w-full text-right">{{ loggedUser.companyName }}</div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('users');

export default {
  methods: {
    logout() {
      window.localStorage.removeItem('companyId');
      window.localStorage.removeItem('userId');
      window.localStorage.removeItem('auth-token');
      // this.$router.push('/login');
      window.location.href = '/login';
    },
  },
  data() {
    return {
      dialog: false,
      menu: false,
    };
  },
  computed: {
    ...mapState(['loggedUser']),
    firstLetters() {
      if (this.loggedUser && this.loggedUser.name)
        return this.getAbbreviatedString(this.loggedUser.name, 2);

      return '';
    },
  },
};
</script>

<style scoped>
.user-card {
  background: var(--v-primary-base);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
}
</style>