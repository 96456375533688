import { maskBr } from 'js-brasil';

import { currency } from '@/utils/helpers/currency';

const mapProduct = (product, category) => {
  if (product) {
    return {
      ...product,
      category: category ? category.description : '',
      price: product.price
    };
  }
};
const mapProducts = (products, categories, tributeGroups) => {
  return products.map(product => {
    const category = categories.filter(cat => +cat.id === +product.categoryId);

    if (product.tribute && product.tribute.tributeGroupId) {
      const tributeGroup = tributeGroups.filter(
        trib => +trib.id == +product.tribute.tributeGroupId
      );

      product.tribute.tributeGroup = tributeGroup.length > 0 ? tributeGroup[0] : null;
    }

    return mapProduct(
      product,
      category.length > 0 ? category[0] : null,
    );
  });
};

const mapCategory = category => {
  return {
    ...category,
    statusText: category.isActive ? 'Ativa' : 'Inativa'
  };
};
const mapCategories = categories => {
  return categories.map(category => {
    return mapCategory(category);
  });
};

const mapVariation = variation => {
  return {
    ...variation,
    labelRequired: variation.isRequired ? 'Obrigatório' : 'Opcional'
  };
};

const mapVariations = variations => {
  return variations.map(variation => {
    return mapVariation(variation);
  });
};

export { mapCategory, mapCategories, mapProducts, mapProduct, mapVariations };
