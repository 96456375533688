import OrdersService from './../services/orders-service';
import { mensageTest } from '@/utils/helpers/testPermission';
import * as ClientTypes from '../../clients/store/mutation-types';
import * as ProductsTypes from '@/modules/products/store/mutation-types';
import * as types from './mutation-types';
import * as BillTypes from '@/modules/financial/submodules/bills/store/mutation-types';
import * as FinancialTypes from '../../../modules/financial/submodules/financial-transactions/store/mutation-types';

import { print } from '@/utils/helpers/print';

export default {
  deleteOrder: async ({ commit, dispatch }, { order }) => {
    const response = await OrdersService.delete(order.sketchId);
    if (response.status > 300) {
      // permission error
      mensageTest(response);
    }
    commit(types.DELETE_ORDER, { order });

    dispatch('pdv/getFinancialTransactions', {}, { root: true });
    dispatch('pdv/getAllBills', {}, { root: true });
  },
  editOrder: async ({ commit }, { order }) => {
    try {
      const response = await OrdersService.edit(order);
      if (response.status > 300) {
        // permission error
        mensageTest(response);
      }
      commit(types.EDIT_ORDER, { order: response.data });
    } catch (err) {
      // alert(err);
    }
  },
  cancelOrderAndBills: async ({ commit }, { orderId }) => {
    let response = await OrdersService.cancelOrderAndBills(orderId);
    if (response.status > 300) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_ORDER, {
      order: response.data.order,
    });
    await Promise.all(
      response.data.bills.map(async ({ id }) => {
        await commit(
          `pdv/${BillTypes.DELETE_BILL}`,
          { billId: id },
          { root: true }
        );
      })
    );
  },
  startIntegration: async (store, order) => {
    try {
      const response = await OrdersService.startIntegration(order);
      if (response.status > 300) {
        // permission error
        mensageTest(response);
      }
      if (!response) {
        throw 'err';
      }

      return response;
    } catch (e) {
      console.log(e);
    }
  },
  cancelTefPayment: async (store, payment) => {
    try {
      const response = await OrdersService.cancelTefPayment(payment);

      if (!response) {
        throw 'err';
      }

      return response;
    } catch (e) {
      console.log(e);
    }
  },
  validateXml: async (store, { order }) => {
    try {
      const response = await OrdersService.validateXml(order);
      if (response.status > 300) {
        // permission error
        mensageTest(response);
      }
      if (!response) {
        throw 'err';
      }

      return response;
    } catch (e) {
      console.log(e);
    }
  },
  createOrder: async (store, { order }) => {
    const { commit, dispatch } = store;

    const response = await OrdersService.create(order);
    if (response.status > 300) {
      // permission error
      mensageTest(response);
    }
    if (!response) {
      throw 'err';
    }
    if (response.data.updated) {
      commit(types.EDIT_ORDER_SKETCH, { order: response.data.updated });
      return response.data.updated;
    } else {
      commit(types.CREATE_ORDER, { order: response.data.order });
    }

    if (response.data.order?.updatedClient) {
      commit(
        `clients/${ClientTypes.EDIT_CLIENT}`,
        { client: response.data.order.updatedClient },
        { root: true }
      );
    }
    if (response.data.order?.isContingency) {
      commit(types.ADD_ORDERS_CONTINGENCY, { order: response.data.order });
    }

    // dispatch("pdv/getAllFinancialCategories", {}, { root: true });
    // dispatch("pdv/getFinancialTransactions", {}, { root: true });
    // dispatch("pdv/getAllBills", {}, { root: true });

    if (
      !!response.data.financialTransactions &&
      !!response.data.financialTransactions.id &&
      response.data.financialTransactions != undefined
    ) {
      commit(
        `pdv/${FinancialTypes.CREATE_FINANCIAL_TRANSACTION}`,
        { financialTransaction: response.data.financialTransactions },
        { root: true }
      );
    }
    if (response.data?.createdBills?.length > 0) {
      commit(
        `pdv/${BillTypes.CREATE_BILL}`,
        { bill: response.data.createdBills },
        { root: true }
      );
    }
    if (!!response.data.category) {
      commit(
        `pdv/${FinancialTypes.UPSERT_FINANCIAL_CATEGORIES}`,
        { financialCategory: response.data.category },
        { root: true }
      );
    }

    return { order: response.data.order, bills: response.data.createdBills };
  },
  cancelNf: async ({ commit, dispatch }, { orderId, reason }) => {
    const response = await OrdersService.cancelNf({ orderId, reason });
    if (response.status > 300) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_ORDER, { order: response.data });

    // response.data.updatedProducts.map((product) => {
    //   commit(`products/${ProductsTypes.EDIT_PRODUCT}`, { product: product }, { root: true });
    // })
    dispatch('pdv/getFinancialTransactions', {}, { root: true });
    dispatch('pdv/getAllBills', {}, { root: true });
    // commit(types.CREATE_ORDER, { order: response.data });
  },
  correctNf: async ({ commit }, { orderId, correctionText }) => {
    const response = await OrdersService.correctNf({
      orderId,
      correctionText,
    });
    console.log(response);
    if (response.status > 300) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_ORDER, { order: response.data });

    return response;
  },
  getOrder: async ({ commit }, id) => {
    try {
      const response = await OrdersService.get(id);
      commit(types.LOAD_ORDER, { order: response.data });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getOrders: async (
    { commit },
    {
      page,
      limit,
      order,
      way,
      date,
      mode,
      type,
      nfMod,
      cashBoxId,
      vendor,
      search,
      isSketchs,
    }
  ) => {
    try {
      const response = await OrdersService.getAll(
        page,
        limit,
        order,
        way,
        date,
        mode,
        type,
        nfMod,
        cashBoxId,
        vendor,
        search,
        isSketchs
      );

      commit(types.ORDERS_CONTINGENCY, {
        contingenciesCount: response.data.contingenciesCount,
      });
      commit(types.LIST_ORDERS, { orders: response.data.orders });
      commit(types.SET_LOADING, { loading: false });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getOrdersTableLength: async (
    { commit },
    { date, mode, type, nfMod, cashBoxId, vendor, search, isSketchs }
  ) => {
    try {
      const response = await OrdersService.getOrdersTableLength(
        date,
        mode,
        type,
        nfMod,
        cashBoxId,
        vendor,
        search,
        isSketchs
      );

      commit(types.TABLES_LENGTH, { orders: response.data });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  inutilizeFiscalInvoices: async ({ commit }, data) => {
    try {
      const response = await OrdersService.inutilizeFiscalInvoices(data);
      if (response.status > 300) {
        // permission error
        mensageTest(response);
      }
      commit(types.CREATE_INUTILIZED_INVOICE, {
        inutilizedInvoice: response.data,
      });
    } catch (err) {
      throw err;
    }
  },
  getInutilizedFiscalInvoices: async ({ commit }) => {
    try {
      const response = await OrdersService.getInutilizedFiscalInvoices();
      if (response.status > 300) {
        // permission error
        mensageTest(response);
      }
      commit(types.LIST_INUTILIZED_INVOICES, {
        inutilizedInvoices: response.data,
      });
    } catch (err) {
      console.log(err);
    }
  },
  getVendorData: async ({ commit }, { vendor, date, mode }) => {
    try {
      const response = await OrdersService.getVendorData(vendor, date, mode);

      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  getOrdersMessages: async ({ commit }) => {
    try {
      const response = await OrdersService.getMessages();

      commit(types.LOAD_MESSAGES, { messages: response.data });
    } catch (erro) {
      //alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getLatestOrders: async ({ commit }, { lastId }) => {
    return new Promise((resolve, reject) => {
      OrdersService.getLatest(lastId)
        .then((response) => {
          let arr = [];

          if (!Array.isArray(response.data)) {
            arr.push(response.data);
          } else {
            arr = response.data;
          }
          if (arr.length > 0) {
            commit(types.ADD_ARRAY_ORDERS, { orders: arr });
          }
          resolve(arr);
        })
        .catch(reject);
    });
  },
  sendAllContingency: async ({ commit }) => {
    try {
      const response = await OrdersService.sendAllContingency();
      if (!response.data.isOffline) {
        commit(types.EDIT_ORDERS_CONTINGENCY, {
          orders: response.data.success,
        });
      }
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  sendMessage({ commit }, { socket, message }) {
    socket.emit('SEND_MESSAGE', message);
    commit(types.NEW_MESSAGE, { message });
  },
  setMessageAsSeen: ({ commit }, { orderId }) => {
    commit(types.SET_MESSAGE_AS_SEEN, { orderId, isCompany: true });
  },
  playNotification({ commit }) {
    commit(types.PLAY_NOTIFICATION);
  },
  stopNotification({ commit }) {
    commit(types.STOP_NOTIFICATION);
  },
};
