import * as types from './mutation-types';

export default {
  [types.CREATE_FINANCIAL_ACCOUNT]: (state, { financialAccount }) => {
    state.financialAccounts.push(financialAccount);
  },
  [types.LIST_FINANCIAL_ACCOUNT]: (state, { financialAccounts }) => {
    state.financialAccounts = financialAccounts;
  },
  [types.DELETE_FINANCIAL_ACCOUNT]: (state, { accountId }) => {
    const index = state.financialAccounts.findIndex(t => t.id === accountId);

    if (+index >= 0) {
      state.financialAccounts.splice(index, 1);
    }

    state.bills = state.bills.filter(
      b => b.accountId != accountId
    );

    state.financialTransactions = state.financialTransactions.filter(
      t => t.accountId != accountId
    );
  },
  [types.EDIT_FINANCIAL_ACCOUNT]: (state, { financialAccount }) => {
    const index = state.financialAccounts.findIndex(
      t => t.id === financialAccount.id
    );
    if (+index >= 0) {
      state.financialAccounts.splice(index, 1, financialAccount);
    }
  }
};
