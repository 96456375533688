import { mapCashBoxes, mapCashBox } from './../utils/helpers/cash-boxes';

import { PAYMENT_METHODS_NF } from '../../../utils/enums/index';

import PdvService from '../../pdv/services/pdv-service';

import moment from 'moment';

moment.locale('pt-br');


export default {
  cashBoxes: (state) => mapCashBoxes(state.cashBoxes),
  getCashBox: (state) => (id) =>
    state.cashBoxes.find((cashbox) => cashbox.id == id),
  cashBoxOperationsLength: (state) => state.cashBoxOperationsLength,
  userCashBox: (state, getters, rootState, rootGetters) => {
    // const user = rootState.users.loggedUser;
    const user = state.loggedCashBox;

    if (!user) return null;

    return user;
  },
  isCashBoxOpened: (state, getters, rootState, rootGetters) => {
    const cashBox = state.loggedCashBox;

    if (!cashBox) return false;

    let isOpened = false;
    if (cashBox.lastOperation) {
      if (cashBox.lastOperation.type == '4') {
        isOpened = null;
      } else {
        isOpened = true;
      }
    }
    return isOpened;
  },
  cashBoxesOpen: (state, getters, rootState, rootGetters) => (id) => {
    let cashBox = getters.getCashBox(id);

    if (!cashBox) return false;
    cashBox = mapCashBox(cashBox);

    let register = cashBox.lastOperation;
    if (register != null && register != undefined) {
      if (register.type == '4') {
        cashBox.status = 'Fechado';
      } else {
        cashBox.status = 'Aberto';
      }
    } else {
      cashBox.status = 'Fechado';
    }
    return cashBox;
  },
};
