import { CLIENT_TYPE } from './../utils/names';
import { mapClients, mapClient, totalDebit } from './../utils/helpers/clients.js';

/* eslint eqeqeq: "off" */
export default {
  clients: (state, getters, rootState, rootGetters) => {
    let clients = mapClients(
      state.clients,
      state.clientsDebitGroups,
      rootGetters['pdv/pendingBills']
    )
    // clients = totalDebit(clients)
    return clients
  },
  debtorsClients: (state) => {
    return state.debtorsClients;
  },
  salesClient:(state) => {
    console.log(state)
    return state.allClientSales
  },
  getClientWithoutMasks: state => id =>
    state.clients.find(client => client.id == id),
  // getClient: (state, getters, rootState, rootGetters) => id => {
  //   return mapClient(
  //     state.clients.find(client => client.id == id),
  //     rootGetters['pdv/pendingBills']
  //   );
  // },
  clientsDebitGroups: state => state.clientsDebitGroups,
  persons: (state, getters, rootState, rootGetters) =>{
    let persons = mapClients(
      state.clients.filter(t => t.type == CLIENT_TYPE.PERSON),
      state.clientsDebitGroups,
      rootGetters['pdv/pendingBills']
    )
    // persons = totalDebit(persons)
    return persons
  },
  tablesLength: state => {
    return { clientsLength: state.clientsLength}
  },
  companies: (state, getters, rootState, rootGetters) =>{
    let companies = mapClients(
      state.clients.filter(t => t.type == CLIENT_TYPE.COMPANY),
      state.clientsDebitGroups,
      rootGetters['pdv/pendingBills']
    )
    // companies = totalDebit(companies)
    return companies
  }
};

