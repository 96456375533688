import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/subcategories';

export default {
  create: data => {
    return apiClient.post(suffixEndpoint, data);
  },
  edit: data => {
    return apiClient.put(suffixEndpoint, data);
  },
  delete: id => {
    return apiClient.delete(`${suffixEndpoint}/${id}`);
  }
};
