import moment from 'moment';
import { datetimeBr } from '@/utils/helpers/date';
import { currency } from '@/utils/helpers/currency';

moment.locale("pt-br");

const verifyIfIsLastBillOfRecurrence = (bills, bill) => {
  const sortedBills = bills.sort((a, b) => {
    return moment(a.expirationDate).isBefore(b.expirationDate) ? -1 : 1;
  });

  if (sortedBills.length > 0 && sortedBills[sortedBills.length - 1].id == bill.id) return true;

  return false;
}

const mapBill = (bill, bills = []) => {

  let linkedBills = [];
  let isLastBillOfRecurrence = false;

  if (bill.recurrenceType) {
    linkedBills = getBillsByOriginBill(bills, bill.originBillId || bill.id);
    isLastBillOfRecurrence = verifyIfIsLastBillOfRecurrence(linkedBills, bill);
  }

  return {
    ...bill,
    client: bill.supplier
      ? bill.supplier.name
      : bill.client
        ? bill.client.name
        : '',
    account: bill.account ? bill.account.description : '',
    category: bill.category ? bill.category.description : '',
    formattedExpirationDate: bill.expirationDate ? datetimeBr(bill.expirationDate, "DD/MM/YYYY") : null,
    formattedCreatedAt: datetimeBr(bill.createdAt),
    value: currency(bill.value),
    isLastBillOfRecurrence,
    linkedBills,
    ref: bill,
  };
};

const getBillsByOriginBill = (bills, billId) => {
  return bills.filter(({ originBillId, id }) => originBillId == billId || id == billId);
}

const mapBills = bills => {
  // bills = bills.filter((bill) => bill.value.toFixed(2) > 0)
  return bills.map(bill => mapBill(bill, bills));
};


const isPaidBill = bill => {
  if (bill.paidDate != null) {
    return true
  } {
    return false
  }

  // console.log(typeof bill.value)
  // let value = bill.value
  // if (typeof value == 'string') {
  //   value = bill.value.replace('R$', '');
  //   value = value.replace('.', '');
  //   value = value.replace(',', '.');
  // }
  // if (bill.paidValue >= value) {
  //   return true
  // } {
  //   return false
  // }

  // return bill.financialTransactions.length > 0 
  // console.log(bill.financialTransactions)

  // return (
  //   bill.financialTransactions.reduce(
  //     (accum, current) => accum + current.value,
  //     0
  //   ) >= bill.value
  // );
};

export {
  mapBills,
  mapBill,
  isPaidBill
};
