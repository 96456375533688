<template>
  <v-row style="display: contents">
    <v-text-field
      :placeholder="placeholder"
      solo
      dense
      flat
      clearable
      clear-icon="la-close"
      class="search"
      hide-details
      @click:clear="$emit('clearSend')"
      :value="value"
      @input="$emit('input', $event)"
      v-on:keyup.enter="$emit('send')"
    >
    </v-text-field>
    <v-btn
      style="align-self: center"
      @click="$emit('send')"
      depressed
      color="primary"
      slot="append"
      class="py-5"
    >
      <v-icon color="gray" size="21px">la-search</v-icon>
    </v-btn>
  </v-row>
</template>
<script>
export default {
  props: {
    placeholder: {
      default: 'Pesquisar',
    },
    value: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.search {
  border: 1px solid var(--v-border-base) !important;
  font-size: 0.8rem !important;
  margin: 5px !important;
}
</style>
