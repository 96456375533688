import moment from 'moment';

moment.locale("pt-br");

//Users

const mapUser = (user) => {
  return {
    ...user,
    roleName: user.role ? user.role.name : "",
    createdAt: moment(user.createdAt).format("DD/MM/YYYY HH:mm")
  };
};

const mapUserRole = (userRole) => {
  return {
    ...userRole,
    formattedCreatedAt: moment(userRole.createdAt).format("DD/MM/YYYY HH:mm")
  };
};

const mapUsers = (users) => {
  return users.map(user => {
    return mapUser(user);
  });
};

const mapUserRoles = (array) => {
  return array.map(obj => {
    return mapUserRole(obj);
  });
};

//Vendors

const mapVendor = (vendor) => {
  return {
    ...vendor,
    createdAt: moment(vendor.createdAt).format("DD/MM/YYYY HH:mm"),
    updatedAt: moment(vendor.updatedAt).format("DD/MM/YYYY HH:mm"),
  };
};

const mapVendors = (vendors) => {
  return vendors.map(vendor => {
    return mapVendor(vendor);
  });
};

export { mapUsers, mapUser, mapUserRoles, mapUserRole, mapVendor, mapVendors };
