import { maskBr, validateBr } from 'js-brasil';

import { currency } from '@/utils/helpers/currency';

const maskPhone = phone => {
  if (!phone) {
    return '';
  }

  return validateBr.telefone(phone)
    ? maskBr.telefone(phone)
    : maskBr.celular(phone);
};

const maskCpfCnpj = cpfCnpj => {
  if (!cpfCnpj) {
    return '';
  }

  return validateBr.cpf(cpfCnpj) ? maskBr.cpf(cpfCnpj) : maskBr.cnpj(cpfCnpj);
};

const mapCarrier = carrier => {
  return {
    ...carrier,
    phone: maskPhone(carrier.phone),
    cnpj: maskCpfCnpj(carrier.cnpj),
  };
};

const mapCarriers = carriers => {
  return carriers.map(carrier => mapCarrier(carrier));
};

export { mapCarrier, mapCarriers };
