import {
    CREATE_TAG,
    LIST_TAGS,
    EDIT_TAGS,
    DELETE_TAGS,
    CREATE_ORDER_REQUEST,
    LIST_ORDER_REQUESTS,
    EDIT_ORDER_REQUESTS,
    DELETE_ORDER_REQUESTS
} from './mutation-types';

export default {
    [CREATE_TAG]: (state, { tag }) => {
        state.tags.push(tag);
    },
    [LIST_TAGS]: (state, { tags }) => {
        state.tags = tags;
    },
    [EDIT_TAGS]: (state, { tag }) => {
        const index = state.tags.findIndex(t => t.id === tag.id);
        if (index >= 0) {
            state.tags.splice(index, 1, tag);
        }
    },
    [DELETE_TAGS]: (state, { id }) => {
        const index = state.tags.findIndex(t => t.id === id);
        if (index >= 0) {
            state.tags.splice(index, 1);
        }
    },
    [CREATE_ORDER_REQUEST]: (state, { orderRequest }) => {
        state.ordersRequests.push(orderRequest);
    },
    [LIST_ORDER_REQUESTS]: (state, { orderRequests }) => {
        state.ordersRequests = orderRequests;
    },
    [EDIT_ORDER_REQUESTS]: (state, { orderRequest }) => {
        const index = state.ordersRequests.findIndex(o => o.id === orderRequest.id);
        if (index >= 0) {
            state.ordersRequests.splice(index, 1, orderRequest);
        }
    },
    [DELETE_ORDER_REQUESTS]: (state, { id }) => {
        const index = state.ordersRequests.findIndex(o => o.id === id);
        if (index >= 0) {
            state.ordersRequests.splice(index, 1);
        }
    },
};
