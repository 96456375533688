
const CarrierPageDetails = () => import('../views/CarrierPageDetails');

export default [
  {
    path: 'transportadora/:id/',
    name: 'carrier-details',
    component: CarrierPageDetails
  }
];
