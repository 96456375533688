import {
    CREATE_TAG,
    LIST_TAGS,
    EDIT_TAGS,
    DELETE_TAGS
} from './mutation-types';

export default {
    [CREATE_TAG]: (state, { tag }) => {
        state.tags.push(tag);
    },
    [LIST_TAGS]: (state, { tags }) => {
        state.tags = tags;
    },
    [EDIT_TAGS]: (state, { tag }) => {
        const index = state.tags.findIndex(t => t.id === tag.id);
        if (index >= 0) {
            state.tags.splice(index, 1, tag);
        }
    },
    [DELETE_TAGS]: (state, { id }) => {
        const index = state.tags.findIndex(t => t.id === id);
        if (index >= 0) {
            state.tags.splice(index, 1);
        }
    },
};
