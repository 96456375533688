import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import * as types from '../modules/orders/store/mutation-types';

import UsersModule from '@/modules/configurations/submodules/users/store';

export default new Vuex.Store({
  modules: {
    users: UsersModule
  },
  state: {
    isAppMenuVisible: true,
    isSnackbarVisible: false,
    snackbarMessage: '',
    snackbarColor: 'normal',
  },
  mutations: {
    ['SET_IS_SNACKBAR_VISIBLE']: (state, { isVisible, message, color }) => {
      state.isSnackbarVisible = isVisible;
      if (message) state.snackbarMessage = message;
      if (color) state.snackbarColor = color;
    },
    [`SET_VISIBILITY_APPMENU`]: (state, { isVisible }) => {
      state.isAppMenuVisible = isVisible;
    },
  },
  actions: {
    showMessage: (store, payload) => {
      store.dispatch('setIsSnackbarVisible', {
        isVisible: true,
        ...payload
      });
    },
    setIsSnackbarVisible: ({ commit }, payload) => {
      commit('SET_IS_SNACKBAR_VISIBLE', payload, { root: true });
    },
    setIsAppMenuVisible: ({ commit }, isVisible) => {
      commit('SET_VISIBILITY_APPMENU', { isVisible }, { root: true });
    },
    [`SOCKET_RECEIVE_MESSAGE`]: ({ commit }, data) => {
      commit(`orders/${types.NEW_MESSAGE}`, { message: data }, { root: true });
    },
    [`SOCKET_ORDER_UPDATE`]: ({ commit }, data) => {
      commit(`orders/${types.EDIT_ORDER}`, { order: data }, { root: true });
    },
    [`SOCKET_NEW_ORDER`]: ({ commit }, data) => {
      Vue.prototype.$socket.emit('ENTER_ON_ORDER_CHAT', data.id);
      commit(
        `orders/${types.ADD_ARRAY_ORDERS}`,
        { orders: [data] },
        { root: true }
      );
    },
    [`SOCKET_MESSAGE_SEEN`]: ({ commit }, { orderId, isCompany = false }) => {
      commit(
        `orders/${types.SET_MESSAGE_AS_SEEN}`,
        { orderId: orderId, isCompany },
        { root: true }
      );
    }
  }
});
