import { listaOrigemProdutos } from '@/modules/financial/utils/helpers/nfe-data';

const mapTribute = tribute => {
  const origemDescricao = listaOrigemProdutos.find(
    ({ id }) => +id == +tribute.origem
  ).name;

  return {
    ...tribute,
    origemDescricao,
    productsLength: 0
  };
};

const mapTributes = (tributes, products) => {
  
  return tributes.map(tribute => {
    return mapTribute(tribute);
  });
};

export { mapTributes, mapTribute };
