import apiClient from '@/utils/api-client-without-base-url';


const suffixEndpoint = '/financial/accounts';

export default {
  create: data => apiClient.post(`${suffixEndpoint}`, data),
  edit: data => apiClient.put(`${suffixEndpoint}/${data.id}`, data),
  getAll: () => apiClient.get(`${suffixEndpoint}`),
  delete: id => apiClient.delete(`${suffixEndpoint}/${id}`)
};
