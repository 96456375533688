<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menuDatePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="850px"
      max-height="95vh"
      content-class="tw-max-h-screen tw-overflow-y-auto tw-py-2 md:tw-py-0 tw-bg-white"
      class="tw-w-9/10"
    >
      <template v-slot:activator="{ on }">
        <v-btn v-on="getOn(on)" icon>
          <v-icon>la-calendar</v-icon>
        </v-btn>
      </template>
      <div class="tw-flex tw-flex-col tw-bg-white">
        <div class="tw-flex tw-flex-col md:tw-flex-row">
          <div class="tw-flex tw-flex-col">
            <div class="tw-w-full tw-justify-center tw-flex tw-text-xs mt-5">
              <span class="ml-3">Período</span>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on" class="ml-1"
                    >la-info-circle</v-icon
                  >
                </template>
                <span class="tw-text-xs"
                  >Para informar o período você pode selecionar no calendário
                  <br />
                  a data de início e a data final, ou digitar nos campos
                  abaixo</span
                >
              </v-tooltip>
            </div>
            <div class="tw-flex tw-mx-5 tw-my-2" style="width: 252px">
              <v-text-field
                label="Início"
                outlined
                hide-details
                dense
                ref="startInterval"
                class="tw-mr-2 text-center textfield-small"
                @keyup="changeStartDate"
                :value="computedFormattedStartDate"
              ></v-text-field>
              <v-text-field
                label="Fim"
                outlined
                hide-details
                dense
                class="tw-ml-2 text-center textfield-small"
                @keyup="changeEndDate"
                :value="computedFormattedEndDate"
              ></v-text-field>
            </div>
            <v-date-picker
              ref="datepickerInterval"
              no-title
              class="tw-border-0 tw-border-r datepicker-small"
              locale="pt-BR"
              range
              scrollable
              flat
              :type="dateType"
              v-model="date"
              reactive
              show-adjacent-months
              v-bind="datePickerOptions"
            >
            </v-date-picker>
          </div>
          <div class="tw-bg-white tw-items-start tw-p-5 md:tw-pt-12">
            <v-btn-toggle
              class="tw-flex tw-flex-col tw-justify-center tw-h-full"
              v-model="type"
              active-class="primary--text"
              mandatory
              borderless
              :ripple="false"
            >
              <v-btn
                depressed
                value="today"
                small
                class="py-4 px-5"
                :ripple="false"
                text
                >Hoje</v-btn
              >
              <v-btn
                depressed
                value="thisWeek"
                small
                class="py-4 px-5"
                :ripple="false"
                text
                >Essa semana</v-btn
              >
              <v-btn
                depressed
                value="lastWeek"
                small
                class="py-4 px-5"
                :ripple="false"
                text
                >Semana passada</v-btn
              >
              <v-btn
                depressed
                value="thisMonth"
                small
                class="py-4 px-5"
                :ripple="false"
                text
                >Esse mês</v-btn
              >
              <v-btn
                depressed
                value="lastMonth"
                small
                class="py-4 px-5"
                :ripple="false"
                text
                >Mês passado</v-btn
              >

              <v-btn
                depressed
                value="selectMonth"
                small
                class="py-4 px-5"
                :ripple="false"
                text
                >Selecionar mês</v-btn
              >
              <v-btn
                depressed
                value="all"
                small
                class="py-4 px-5"
                :ripple="false"
                text
                >Todas as datas</v-btn
              >
            </v-btn-toggle>
          </div>
        </div>
        <div class="tw-flex tw-justify-between tw-pb-2 tw-px-4 md:tw-py-4">
          <v-btn text color="primary" @click="menuDatePicker = false">
            Cancelar
          </v-btn>
          <v-btn
            depressed
            class="ml-2 tw-px-8"
            dense
            color="primary"
            @click="submit"
          >
            Filtrar
          </v-btn>
        </div>
      </div>
    </v-menu>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    label: {},
    value: {
      type: Array,
    },
    textFieldOptions: {},
    datePickerOptions: {},
    isDatePickerVisible: false,
    inputHide: false,
    icon: {
      type: Boolean,
      default: false,
    },
    iconProps: {},
    iconEvents: {},
  },
  methods: {
    getOn(on) {
      return { ...on, ...(this.iconEvents || {}) };
    },
    validateDateBr(dateBr) {
      return (
        dateBr.split('/').length == 3 &&
        dateBr.split('/')[0].length == 2 &&
        dateBr.split('/')[1].length == 2 &&
        dateBr.split('/')[2].length == 4
      );
    },
    changeStartDate({ target: { value: dateBr } }) {
      // alert(dateBr);
      // console.log(dateBr);
      if (this.validateDateBr(dateBr)) {
        const converted = this.dateBrToEn(dateBr);
        this.date.splice(0, 1, converted);
      }
    },
    changeEndDate({ target: { value: dateBr } }) {
      if (this.validateDateBr(dateBr)) {
        const converted = this.dateBrToEn(dateBr);
        this.date.splice(1, 1, converted);
      }
    },
    submit() {
      if (this.date.length > 0) {
        if (!this.date[1]) this.date.push(this.date[0]);

        if (moment(this.date[0]).isAfter(this.date[1])) {
          const firstDate = this.date[0];
          const secondDate = this.date[1];

          this.date.splice(0, 1, secondDate);
          this.date.splice(1, 1, firstDate);
        }

        if (this.dateType == 'month') {
          this.date.splice(
            1,
            1,
            moment(this.date[1]).clone().endOf('month').format('YYYY-MM-DD')
          );
        }
      }

      this.$emit('change', [...this.date]);
      this.menuDatePicker = false;
    },
  },
  computed: {
    computedFormattedStartDate() {
      return this.datetimeBr(this.date[0], 'DD/MM/YYYY');
    },
    computedFormattedEndDate() {
      if (!this.date[1]) return '';
      return this.datetimeBr(this.date[1], 'DD/MM/YYYY');
    },
  },
  watch: {
    isDatePickerVisible(newValue) {
      if (this.menuDatePicker != newValue) {
        this.menuDatePicker = newValue;
      }
    },
    menuDatePicker(newValue) {
      if (this.isDatePickerVisible != newValue) {
        if (newValue) this.$emit('openMenu', event);
        else this.$emit('closeMenu', event);
      }
    },
    value(newValue) {
      // this.dateFormatEn = this.dateBrToEn(newValue);
      this.date = [...newValue];
    },
    type(val) {
      const currentDay = moment();

      switch (val) {
        case 'today':
          this.date.splice(0, 1, currentDay.format(this.defaultDateFormat));
          this.date.splice(1, 1, currentDay.format(this.defaultDateFormat));
          break;

        case 'thisWeek':
          this.date.splice(
            0,
            1,
            currentDay.clone().startOf('week').format(this.defaultDateFormat)
          );
          this.date.splice(
            1,
            1,
            currentDay.clone().endOf('week').format(this.defaultDateFormat)
          );
          break;

        case 'lastWeek':
          this.date.splice(
            0,
            1,
            currentDay
              .clone()
              .subtract('week', 1)
              .startOf('week')
              .format(this.defaultDateFormat)
          );
          this.date.splice(
            1,
            1,
            currentDay
              .clone()
              .subtract('week', 1)
              .endOf('week')
              .format(this.defaultDateFormat)
          );
          break;

        case 'thisMonth':
          this.date.splice(
            0,
            1,
            currentDay.clone().startOf('month').format(this.defaultDateFormat)
          );
          this.date.splice(
            1,
            1,
            currentDay.clone().endOf('month').format(this.defaultDateFormat)
          );
          break;

        case 'lastMonth':
          this.date.splice(
            0,
            1,
            currentDay
              .clone()
              .subtract('month', 1)
              .startOf('month')
              .format(this.defaultDateFormat)
          );
          this.date.splice(
            1,
            1,
            currentDay
              .clone()
              .subtract('month', 1)
              .endOf('month')
              .format(this.defaultDateFormat)
          );
          break;
        case 'customInterval':
          this.$refs.startInterval.focus();
          break;
        case 'selectMonth':
          this.dateType = 'month';
          break;
        case 'all':
          this.date = [];
      }

      if (this.dateType == 'month' && val != 'selectMonth') {
        this.dateType = 'date';
      }

      this.$refs.datepickerInterval.tableDate =
        this.date[1].split('-')[0] + '-' + this.date[1].split('-')[1];
    },
    date(val) {},
  },
  data() {
    return {
      defaultDateFormat: 'YYYY-MM-DD',
      menuDatePicker: false,
      dateType: 'date',
      dateFormatEn: '',
      date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      type: '',
    };
  },
};
</script>
<style>
.datepicker-small .v-date-picker-header {
  padding: 0px 16px;
}

.datepicker-small .v-date-picker-table--date th {
  padding: 4px 0px;
}

.datepicker-small .v-date-picker-table--date .v-btn {
  width: 28px;
  height: 28px;
}

.datepicker-small .v-date-picker-table {
  height: 200px;
}

.datepicker-small .v-date-picker-years {
  height: 237px;
}

.datepicker-small .v-date-picker-table--month td {
  height: 50px;
}

.datepicker-small .v-date-picker-header__value button {
  font-size: 14px;
}

.textfield-small .v-input__slot {
  min-height: 30px !important;
}

.textfield-small input {
  font-size: 13px;
}
</style>
