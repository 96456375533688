import {
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  DELETE_PRODUCT,
  LIST_PRODUCTS,
  LIST_ALL_PRODUCTS,
  CREATE_CATEGORY,
  EDIT_CATEGORY,
  LIST_CATEGORIES,
  SET_LOADING_PRODUCTS,
  DELETE_CATEGORY,
  CREATE_VARIATION,
  EDIT_VARIATION,
  DELETE_VARIATION,
  LIST_VARIATIONS,
  CREATE_OPTION,
  EDIT_OPTION,
  DELETE_OPTION,
  CREATE_SUBCATEGORY,
  EDIT_SUBCATEGORY,
  DELETE_SUBCATEGORY,
  SET_NCM,
  TABLES_LENGTH,
  PUSH_PRODUCTS,
  CREATE_REFERENCE,
  EDIT_REFERENCE,
  DELETE_REFERENCE,
  SET_DEFAULT_REFERENCE,
} from './mutation-types';

export default {
  // products
  [CREATE_PRODUCT]: (state, { product }) => {
    state.products.push(product);
  },
  [EDIT_PRODUCT]: (state, { product }) => {
    // console.log(product)
    const index = state.products.findIndex((p) => p.id === product.id);
    if (index >= 0) state.products.splice(index, 1, product);
  },
  [DELETE_PRODUCT]: (state, { id }) => {
    const index = state.products.findIndex((t) => t.id === id);

    if (index >= 0) state.products.splice(index, 1);
  },
  [LIST_PRODUCTS]: (state, { products }) => {
    // products.map((product) => {
    //   const index = state.products.findIndex(p => p.id == product.id);
    //   // console.log(index)
    //   if (index < 0) state.products.push(product);
    // })
    state.products = products;
  },
  [LIST_ALL_PRODUCTS]: (state, { allProducts }) => {
    allProducts.map((p) => {
      state.allProducts.push(p);
    });
  },
  [PUSH_PRODUCTS]: (state, { products }) => {
    products.map((product) => {
      const index = state.products.findIndex((p) => p.id == product.id);
      if (index < 0) state.products.push(product);
    });
    // state.products = products;
  },

  // categories
  [CREATE_CATEGORY]: (state, { category }) => {
    state.categories.push(category);
  },
  [EDIT_CATEGORY]: (state, { category: cat }) => {
    const category = Array.isArray(cat) ? cat[0] : cat;

    const index = state.categories.findIndex((t) => t.id === category.id);

    if (index >= 0) state.categories.splice(index, 1, category);
  },
  [DELETE_CATEGORY]: (state, { id }) => {
    const index = state.categories.findIndex((t) => t.id === id);

    if (index >= 0) state.categories.splice(index, 1);
  },
  [LIST_CATEGORIES]: (state, { categories }) => {
    state.categories = categories;
  },
  [SET_LOADING_PRODUCTS]: (state, { isLoadingProducts }) => {
    state.isLoadingProducts = isLoadingProducts;
  },

  // variations
  [CREATE_VARIATION]: (state, { variation }) => {
    state.variations.push(variation);
  },
  [EDIT_VARIATION]: (state, { variation }) => {
    const index = state.variations.findIndex((t) => t.id === variation.id);

    if (index >= 0) state.variations.splice(index, 1, variation);
  },
  [DELETE_VARIATION]: (state, { id }) => {
    const index = state.variations.findIndex((t) => t.id === id);

    if (index >= 0) state.variations.splice(index, 1);
  },
  [LIST_VARIATIONS]: (state, { variations }) => {
    state.variations = variations;
  },
  [CREATE_SUBCATEGORY]: (state, { subcategory }) => {
    const categoryIndex = state.categories.findIndex(
      (c) => c.id == subcategory.categoryId
    );

    const category = state.categories[categoryIndex];

    category.subcategories.push(subcategory);

    if (categoryIndex >= 0) state.categories.splice(categoryIndex, 1, category);
  },
  [EDIT_SUBCATEGORY]: (state, { subcategory }) => {
    const categoryIndex = state.categories.findIndex(
      (c) => c.id == subcategory.categoryId
    );

    const category = { ...state.categories[categoryIndex] };
    const subcategoryIndex = category.subcategories.findIndex(
      (s) => s.id == subcategory.id
    );

    category.subcategories.splice(subcategoryIndex, 1, subcategory);

    state.categories.splice(categoryIndex, 1, category);
  },
  [DELETE_SUBCATEGORY]: (state, { subcategory }) => {
    const categoryIndex = state.categories.findIndex(
      (c) => c.id == subcategory.categoryId
    );

    const category = { ...state.categories[categoryIndex] };
    const subcategoryIndex = category.subcategories.findIndex(
      (s) => s.id == subcategory.id
    );

    category.subcategories.splice(subcategoryIndex, 1);

    state.categories.splice(categoryIndex, 1, category);
  },
  [CREATE_OPTION]: (state, { option, variationId }) => {
    const variationIndex = state.variations.findIndex(
      (v) => v.id == variationId
    );
    const variation = state.variations[variationIndex];

    variation.options.push(option);
    state.variations.splice(variationIndex, 1, variation);
  },
  [EDIT_OPTION]: (state, { option }) => {
    const variationIndex = state.variations.findIndex(
      (t) => t.id === option.variationId
    );
    const variation = { ...state.variations[variationIndex] };

    const optionIndex = variation.options.findIndex(
      (opt) => opt.id == option.id
    );
    variation.options.splice(optionIndex, 1, option);

    state.variations.splice(variationIndex, 1, variation);
  },
  [DELETE_OPTION]: (state, { option }) => {
    const variationIndex = state.variations.findIndex(
      (v) => v.id === option.variationId
    );

    if (variationIndex < 0) return;

    const variation = { ...state.variations[variationIndex] };
    const optionIndex = variation.options.findIndex((o) => o.id === option.id);

    variation.options.splice(optionIndex, 1);

    state.variations.splice(variationIndex, 1, variation);
  },
  // [SET_NCM]: (state, { ncmList }) => {
  //   state.ncmList = ncmList
  // },
  [TABLES_LENGTH]: (state, { products }) => {
    state.productsLength = products;
  },
  [CREATE_REFERENCE]: (
    state,
    { supplierId, productId, supplierProductCode, id, supplier, isDefault }
  ) => {
    const productIndex = state.products.findIndex((p) => p.id == productId);
    state.products[productIndex].productSuppliers.push({
      id,
      supplier,
      supplierId,
      productId,
      supplierProductCode,
      isDefault,
    });
  },
  [DELETE_REFERENCE]: (state, { referenceId, productId }) => {
    const productIndex = state.products.findIndex((p) => p.id == productId);
    const referenceIndex = state.products[
      productIndex
    ].productSuppliers.findIndex((r) => r.id == referenceId);
    state.products[productIndex].productSuppliers.splice(referenceIndex, 1);
  },
  [SET_DEFAULT_REFERENCE]: (state, { referenceId, productId }) => {
    const productIndex = state.products.findIndex((p) => p.id == productId);

    state.products[productIndex].productSuppliers.forEach((p) => {
      p.isDefault = p.id == referenceId;
    });
  },
};
