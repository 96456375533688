import actions from './actions';
import getters from './getters';
import mutations from './mutations';


const state = {
  erro: undefined,
  company: {},
  paymentMethods: [],
  loading: true
};

export default {
  namespaced: true,
  state,
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  }
};
