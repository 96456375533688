<template>
  <div class="quantity-button-container">
    <div class="quantity-button-text">
      <p class="small-text" v-if="text">{{ text }}</p>
      <input ref="percentageRef" type="number" class="input-quantity-button-text-number" v-model="localValue"
        :disabled="disabled" @keyup="handleKeyUp" />
    </div>
    <div class="quantity-button-actions tw-space-y-2" v-if="!disabled">
      <v-icon color="black" @click="decrement">la la-minus</v-icon>
      <v-icon color="black" @click="increment">la la-plus</v-icon>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    totalValue: {
      default: null
    }
  },
  data() {
    return {
      localValue: 0,
    }
  },
  watch: {
    value(val) {
      let formattedVal = val
      if (formattedVal >= this.min && formattedVal <= this.max) {
        this.localValue = formattedVal;
      }
    }
  },
  methods: {
    focus(){
      this.$refs.percentageRef.focus()
    },
    decrement() {
      let newValue = this.value;
      if (this.min !== null && this.value <= this.min) {
        newValue = this.min;
      } else {
        newValue--;
      }
      this.localValue = newValue
      this.$emit('input', newValue);
    },
    increment() {
      let newValue = this.value;
      if (this.max && this.value >= this.max) {
        newValue = this.max;
      } else {
        newValue++;
      }

      this.localValue = newValue
      this.$emit('input', newValue);
    },


    handleKeyUp(e) {
      let newValue = e.target.value ? parseFloat(e.target.value) : 0;
      if (newValue <= this.min) {
        newValue = this.min;
      } else if (newValue >= this.max) {
        newValue = this.max;
      }
      this.localValue = newValue
      this.$emit('input', newValue);
    },
  },
};
</script>

<style>
.quantity-button-container {
  display: flex;
  justify-content: space-between;
  font-size: 0.2rem;
  border: 1px solid #d9d9d9;
  border-radius: 1rem;
  padding: 1rem;
  min-height: 6.2rem;
}

.quantity-button-actions .v-icon {
  border: 1px solid #555555;
  border-radius: 50rem;
  padding: 0.2rem;
  font-size: 1rem;
}

.quantity-button-text {
  /* max-width: 6rem; */
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

.small-text {
  font-size: 0.9rem;
}

.input-quantity-button-text-number {
  font-size: 1.2rem;
  width: 100%;
  outline: none;
}
</style>
