<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menuDatePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          :label="label"
          v-on="on"
          v-bind="textFieldOptions"
          v-if="!inputHide && !icon"
          @blur="onBlur"
        ></v-text-field>
        <v-btn v-else-if="icon" v-on="getOn(on)" icon>
          <v-icon>la-calendar</v-icon>
        </v-btn>
        <span v-else></span>
      </template>
      <v-date-picker
        ref="picker"
        locale="pt-BR"
        no-title
        :value="value"
        @input="change"
        v-bind="datePickerOptions"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    label: {},
    value: {},
    textFieldOptions: {},
    datePickerOptions: {},
    isDatePickerVisible: false,
    inputHide: false,
    icon: {
      type: Boolean,
      default: false,
    },
    iconProps: {},
    iconEvents: {},
  },
  methods: {
    getOn(on) {
      console.log(on);
      return { ...on, ...(this.iconEvents || {}) };
    },
    change(event) {
      this.menuDatePicker = false;

      this.$emit('input', event);
    },
    printOn(on) {
      console.log(on);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    onBlur(){
      alert(this.dateFormatted);
      this.$emit("input", this.parseDate(this.dateFormatted));
    }
  },
  watch: {
    isDatePickerVisible(newValue) {
      if (this.menuDatePicker != newValue) {
        this.menuDatePicker = newValue;
      }
    },
    menuDatePicker(newValue) {
      if (this.isDatePickerVisible != newValue) {
        if (newValue) this.$emit('openMenu', event);
        else this.$emit('closeMenu', event);
      }
    },
    value(newValue) {
      this.dateFormatted = this.formatDate(newValue);
    },
  },
  data() {
    return {
      menuDatePicker: false,
      dateFormatted: '',
    };
  },
};
</script>
