import { render, staticRenderFns } from "./ProductIcon.vue?vue&type=template&id=432c9544&scoped=true&"
var script = {}
import style0 from "./ProductIcon.vue?vue&type=style&index=0&id=432c9544&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "432c9544",
  null
  
)

export default component.exports