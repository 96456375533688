import OrderTagsService from './../services/order-tags-service';
import OrderRequestService from './../services/order-request-service';
import * as types from './mutation-types';
import { mensageTest } from '@/utils/helpers/testPermission';
export default {
  getOrderRequests: async ({ commit }) => {
    try {
      const response = await OrderRequestService.getAll();
      commit(types.LIST_ORDER_REQUESTS, { orderRequests: response.data });
    } catch (err) {
      console.log(err);
    }
  },
  createOrderRequest: async ({ commit }, { orderRequest }) => {
    try {
      const response = await OrderRequestService.create({ orderRequest });
      commit(types.CREATE_ORDER_REQUEST, { orderRequest: response.data });
      if (response.data) return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  editOrderRequest: async ({ commit }, { orderRequest }) => {
    try {
      const response = await OrderRequestService.edit({ orderRequest });
      commit(types.EDIT_ORDER_REQUESTS, { orderRequest: response.data });
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  deleteOrderRequest: async ({ commit }, { orderRequest }) => {
    try {
      const response = await OrderRequestService.delete(orderRequest.id);
      commit(types.DELETE_ORDER_REQUESTS, { id: response.data.id });
    } catch (err) {
      console.log(err);
    }
  },
  createTag: async ({ commit }, { tag }) => {
    try {
      const response = await OrderTagsService.create({ tag });
      commit(types.CREATE_TAG, { tag: response.data });
    } catch (err) {
      console.log(err);
    }
  },
  getTags: async ({ commit }) => {
    try {
      const response = await OrderTagsService.getAll();
      commit(types.LIST_TAGS, { tags: response.data });
    } catch (err) {
      console.log(err);
    }
  },
  editTag: async ({ commit }, { tag }) => {
    try {
      const response = await OrderTagsService.edit({ tag });
      commit(types.EDIT_TAGS, { tag: response.data });
    } catch (err) {
      console.log(err);
    }
  },
  deleteTags: async ({ commit }, id) => {
    try {
      await OrderTagsService.delete(id).then((response) => {
        if (response.status == 405) {
          // permission error
          mensageTest(response);
        }
        commit(types.DELETE_TAGS, { id });
      });
    } catch (err) {
      console.log(err);
    }
  },
};
