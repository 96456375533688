import ProductsService from './../services/products-service';
import CategoriesService from './../services/categories-service';
import SubCategoriesService from './../services/subcategories-service';
import VariationsService from './../services/variations-service';
import OptionsService from './../services/options-service';
import { mensageTest } from '@/utils/helpers/testPermission';
import * as types from './mutation-types';

import {
  UPSERT_PROMOTION,
  CREATE_PROMOTION,
} from '../submodules/promotions/store/mutation-types';

export default {
  // products
  createProduct: ({ commit }, { product }) => {
    return new Promise((resolve, reject) => {
      ProductsService.create(product)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          const { data } = response;
          commit(types.CREATE_PRODUCT, { product: data });

          const { promotion } = data;

          if (promotion) {
            commit(CREATE_PROMOTION, {
              promotion: { ...promotion, product: data },
            });
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  createProductArray: ({ commit }, { products }) => {
    return new Promise((resolve, reject) => {
      ProductsService.createArrayProducts(products)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          const { productsSuccess, updatedProducts, productsError } =
            response.data;
          for (const product of productsSuccess) {
            commit(types.CREATE_PRODUCT, { product });
          }
          for (const product of updatedProducts) {
            commit(types.EDIT_PRODUCT, { product });
          }
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  editProduct: async ({ commit }, { product }) => {
    const response = await ProductsService.edit(product);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    const { data } = response;
    commit(types.EDIT_PRODUCT, { product: data });

    const { promotion } = data;
    if (promotion) {
      commit(UPSERT_PROMOTION, { promotion: { ...promotion, product: data } });
    }

    return response;
  },
  simpleEditProduct: async ({ commit }, { product }) => {
    const response = await ProductsService.simpleEdit(product);
    const { data } = response;
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_PRODUCT, { product: data });

    const { promotion } = data;
    if (promotion) {
      commit(UPSERT_PROMOTION, { promotion: { ...promotion, product: data } });
    }

    return response;
  },
  deleteProduct: async ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      ProductsService.delete(id)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.DELETE_PRODUCT, { id });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // deleteArrayProducts: async ({ commit }, { ids }) => {
  //   await ProductsService.delete(product.id);
  //   commit(types.DELETE_PRODUCT, { product });
  // },
  getProduct: async ({ commit }, id) => {
    try {
      const response = await ProductsService.get(id);
      console.log(response);
      commit(types.LOAD_PRODUCT, { product: response.data });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getProductById: async ({ commit }, id) => {
    try {
      const response = await ProductsService.get(id);
      return response.data;
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getProductByName: async ({ commit }, { name, gtin }) => {
    try {
      const response = await ProductsService.getByName(name, gtin);
      if (response.data != null) {
        // commit(types.PUSH_PRODUCTS, { products: response.data });
        return response.data;
      } else {
        return null;
      }
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  },
  getProducts: async (
    { commit },
    {
      page,
      limit,
      order,
      way,
      search,
      filterImage,
      filterStatus,
      filterCombo,
      all,
    }
  ) => {
    commit(types.SET_LOADING_PRODUCTS, { isLoadingProducts: true });
    try {
      const response = await ProductsService.getAll(
        page,
        limit,
        order,
        way,
        search,
        filterImage,
        filterStatus,
        filterCombo,
        all
      );
      if (all == 0) {
        commit(types.LIST_PRODUCTS, { products: response.data });
      } else if (all == 1) {
        commit(types.LIST_ALL_PRODUCTS, { allProducts: response.data });
      }
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }

    commit(types.SET_LOADING_PRODUCTS, { isLoadingProducts: false });
  },

  // categories
  createCategory: ({ commit }, { category }) => {
    return new Promise((resolve, reject) => {
      CategoriesService.create(category)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.CREATE_CATEGORY, { category: response.data });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  editCategory: ({ commit }, { category }) => {
    // console.log(12312321321321);
    return new Promise((resolve, reject) => {
      CategoriesService.edit(category)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.EDIT_CATEGORY, { category: response.data });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  deleteCategory: async ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      CategoriesService.delete(id)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.DELETE_CATEGORY, { id });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getCategories: async ({ commit }) => {
    try {
      const response = await CategoriesService.getAll();
      commit(types.LIST_CATEGORIES, { categories: response.data });
    } catch (erro) {
      // alert(erro);
    }
  },
  // subcategories

  createSubcategory: ({ commit }, { subcategory }) => {
    return new Promise((resolve, reject) => {
      SubCategoriesService.create(subcategory)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.CREATE_SUBCATEGORY, { subcategory: response.data });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  editSubcategory: ({ commit }, { subcategory }) => {
    return new Promise((resolve, reject) => {
      SubCategoriesService.edit(subcategory)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.EDIT_SUBCATEGORY, { subcategory: response.data });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  deleteSubcategory: ({ commit }, { subcategory }) => {
    return new Promise((resolve, reject) => {
      SubCategoriesService.delete(subcategory.id)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.DELETE_SUBCATEGORY, { subcategory });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  // variations
  createVariation: ({ commit }, { variation }) => {
    return new Promise((resolve, reject) => {
      VariationsService.create(variation)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.CREATE_VARIATION, { variation: response.data });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  editVariation: ({ commit }, { variation }) => {
    return new Promise((resolve, reject) => {
      VariationsService.edit(variation)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.EDIT_VARIATION, { variation: response.data });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  deleteVariation: async ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      VariationsService.delete(id)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.DELETE_VARIATION, { id });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  getVariations: async ({ commit }) => {
    try {
      const response = await VariationsService.getAll();
      commit(types.LIST_VARIATIONS, { variations: response.data });
    } catch (error) {}
  },

  // options
  createOption: ({ commit }, { option, variationId }) => {
    return new Promise((resolve, reject) => {
      OptionsService.create(option, variationId)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.CREATE_OPTION, { option: response.data, variationId });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  deleteOption: async ({ commit }, { option }) => {
    return new Promise((resolve, reject) => {
      OptionsService.delete(option)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.DELETE_OPTION, { option });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },

  editOption: ({ commit }, { option }) => {
    return new Promise((resolve, reject) => {
      OptionsService.edit(option)
        .then((response) => {
          if (response.status == 405) {
            // permission error
            mensageTest(response);
          }
          commit(types.EDIT_OPTION, { option: response.data });
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
  returnStockProduct: async ({ commit }, { product, orderId }) => {
    const response = await ProductsService.returnStock(product, orderId);
    if (response.status == 405) {
      // permission error
      mensageTest(response);
    }
    commit(types.EDIT_PRODUCT, { product: response });
  },
  getProductsTablesLength: async (
    { commit },
    { search, filterImage, filterStatus, filterCombo }
  ) => {
    try {
      const response = await ProductsService.getProductsLength(
        search,
        filterImage,
        filterStatus,
        filterCombo
      );
      commit(types.TABLES_LENGTH, { products: response.data });
    } catch (erro) {
      console.log(erro);
    }
  },
  createReference: async (
    { commit },
    { productId, supplierId, supplierProductCode, isDefault }
  ) => {
    try {
      const { data: returnedReference } = await ProductsService.createReference(
        {
          productId,
          supplierId,
          supplierProductCode,
          isDefault
        }
      );

      commit(types.CREATE_REFERENCE, { ...returnedReference, productId });
    } catch (e) {
      console.log(e);
    }
  },
  deleteReference: async ({ commit }, { referenceId, productId }) => {
    try {
      const deletedReference = await ProductsService.deleteReference(
        referenceId
      );
      commit(types.DELETE_REFERENCE, { productId, referenceId });
    } catch (e) {
      console.log(e);
    }
  },
  setDefaultReference: async ({ commit }, { referenceId, productId }) => {
    try {
      await ProductsService.setDefaultReference(referenceId, productId);

      commit(types.SET_DEFAULT_REFERENCE, { referenceId, productId });
    } catch (e) {
      console.log(e);
    }
  },
  // setNcm: ({ commit }, { ncmList }) => {
  //   commit(types.SET_NCM, { ncmList: ncmList });
  // },
};
