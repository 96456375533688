import TagsService from './../services/tags-services';
import * as types from './mutation-types';
import { mensageTest } from '@/utils/helpers/testPermission'
export default {
  createTagModel: ({ commit }, { saveLayout, fieldDatas }) => {
    return new Promise((resolve, reject) => {
      try {
        TagsService.create({ saveLayout, fieldDatas }).then(response => {
          if(response.status == 405){ // permission error
            mensageTest(response)
          }
          commit(types.CREATE_TAG, { tag: response.data});
          resolve(response);
        })
          .catch(error => reject(error));
      } catch (err) {
        console.log(err)
      }
    })
  },
  getTagModels: async ({ commit }) => {
    try {
      const response = await TagsService.getAll();
      commit(types.LIST_TAGS, { tags: response.data });
    } catch (err) {
      console.log(err)
    }
  },
  editTagModels: async ({ commit }, { saveLayout, fieldDatas }) => {
    try {
      await TagsService.edit({ saveLayout, fieldDatas }).then(response => {
        if(response.status == 405){ // permission error
          mensageTest(response)
        }
        commit(types.EDIT_TAGS, { tag: response.data });
      })
    } catch (err) {
      console.log(err)
    }
  },
  deleteTagModels: async ({ commit },  id ) => {
    try {
      await TagsService.delete(id).then(response => {
        if(response.status == 405){ // permission error
          mensageTest(response)
        }
        commit(types.DELETE_TAGS, { id });
      })
    } catch (err) {
      console.log(err)
    }
  },
}