import './utils/global-components';

import Vue from 'vue';
import App from './App.vue';
import store from '@/store/index';
import vuetify from '@/plugins/vuetify';
import '@/plugins/print';
import '@/plugins/external-components';
import keyCodes from '@/plugins/key-codes';

import { maskBr } from 'js-brasil';

import moment from 'moment';
moment.locale('pt-br');

/* import io from 'socket.io-client';
import VueSocketIO from 'vue-socket.io'; */

/* Vue.use(
  new VueSocketIO({
    debug: true,
    connection: io.connect('ws://localhost:3005', {
      transports: ['websocket'],
    }),
  })
); */

import { dateBrToEn as convertDateBrToEn } from './utils/helpers/date';

import VueMask from 'v-mask';
Vue.use(VueMask);

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

import { currency } from '@/utils/helpers/currency';

Vue.config.productionTip = false;
Vue.directive('click-outside-custom', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        if (vnode.context[binding.expression])
          vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

import cep from 'cep-promise';
import { validateBr } from 'js-brasil';

const globalMixin = {
  data() {
    return {
      tableItemsPerPage: 9,
      // isMobile: false,
      showTableSelect: Boolean(window.innerWidth > 768),
    };
  },
  methods: {
    checkPermissions(
      permissions,
      loggedUser = store.state.users.loggedUser,
      type = 'and'
    ) {
      if (loggedUser.role.name == 'Administrador') return true;

      if (type == 'and')
        return permissions.every((p) =>
          loggedUser.role.mappedPermissions.includes(p)
        );
      return permissions.some((p) =>
        loggedUser.role.mappedPermissions.includes(p)
      );
    },
    getErrorMessageFromRequestError(err) {
      if (err.response && err.response.status == 401) {
        throw 'redirect';
      }

      console.log(err.response);

      try {
        if (err.response) {
          const { response } = err;

          if (
            response.data &&
            response.data.errors &&
            Array.isArray(response.data.errors)
          )
            return response.data.errors[0].message;
          else if (response.data && response.data.message)
            return response.data.message;
          // else if (response.data) return response.data;
          else if (response.message) return response.message;

          if (err.response.status == 405)
            return 'Você não tem permissão para realizar essa ação';
        } else if (err.message) return err.message;
        else if (err.description) return err.description;

        return 'Erro ao realizar a requisição';
      } catch (err) {
        return 'Erro ao realizar a requisição';
      }
    },
    loadRowsPerPage() {
      // return parseInt(window.innerHeight / 65);
      this.tableItemsPerPage = this.getRowsPerPage();
      // console.log(this.tableItemsPerPage);
    },
    isMobileTest() {
      if (window.innerWidth > 768) {
        return false;
      }
      return true;
    },

    getAbbreviatedString(string, lettersCount) {
      const slices = string.trim().split(' ');

      if (slices.length == 1) return slices[0].slice(0, 1);
      else {
        let letters = '';

        for (let i = 0; i < lettersCount; i++) {
          if (slices[i]) letters += slices[i].slice(0, 1);
        }

        return letters;
      }
    },
    getRowsPerPage() {
      return Math.round(window.innerHeight / 72);
    },
    onResize() {
      this.loadRowsPerPage();
      this.isMobileTest();
    },
    created() {
      this.loadRowsPerPage();
      this.isMobileTest();
    },
    getKeyCodes: () => {
      return { ...keyCodes };
    },
    currencyBr(value) {
      return currency(value);
    },
    calculateMarkup(costPrice, price) {
      const markup = (price * 100) / costPrice - 100;
      // return markup
      return this.roundMoney(markup);
    },
    calculateSalePrice(costPrice, markup) {
      const salePrice = costPrice + costPrice * (markup / 100);

      return this.roundMoney(salePrice);
    },
    roundMoney(value) {
      return Math.round((parseFloat(value) + Number.EPSILON) * 100) / 100;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getIntervalDateText(date0, date1) {
      const formatBr = 'DD/MM/YYYY';

      const startDate = moment(date0);
      const startDateFormat = startDate.format(formatBr);

      const endDate = moment(date1);
      const endDateFormat = endDate.format(formatBr);

      const currentDay = moment();

      if (
        startDateFormat == endDateFormat &&
        startDateFormat == currentDay.format(formatBr)
      )
        return 'Hoje';

      const startWeek = currentDay.clone().startOf('week').format(formatBr);
      const endWeek = currentDay.clone().endOf('week').format(formatBr);

      if (startDateFormat == startWeek && endDateFormat == endWeek)
        return 'Essa semana';

      const lastStartWeek = currentDay
        .clone()
        .subtract('week', 1)
        .startOf('week')
        .format(formatBr);

      const lastEndWeek = currentDay
        .clone()
        .subtract('week', 1)
        .endOf('week')
        .format(formatBr);

      if (startDateFormat == lastStartWeek && endDateFormat == lastEndWeek)
        return 'Semana passada';

      const startThisMonth = currentDay
        .clone()
        .startOf('month')
        .format(formatBr);
      const endThisMonth = currentDay.clone().endOf('month').format(formatBr);

      if (startDateFormat == startThisMonth && endDateFormat == endThisMonth)
        return this.capitalizeFirstLetter(moment().format('MMMM'));

      const startLastMonth = currentDay
        .clone()
        .subtract('month', 1)
        .startOf('month')
        .format(formatBr);

      const endLastMonth = currentDay
        .clone()
        .subtract('month', 1)
        .endOf('month')
        .format(formatBr);

      if (startDateFormat == startLastMonth && endDateFormat == endLastMonth)
        return 'Mês passado';

      if (this.isVisibilityByMonth(startDate, endDate)) {
        const monthName = this.capitalizeFirstLetter(startDate.format('MMMM'));

        if (startDate.format('YYYY') == moment().format('YYYY'))
          return monthName;
        return monthName + ' ' + startDate.format('YYYY');
      }

      return this.formatInterval(startDate, endDate);
    },

    formatInterval(date1, date2) {
      const thisYear = moment().format('YYYY');

      let date = '';

      if (date1.format('DD-MM-YYYY') == date2.format('DD-MM-YYYY')) {
        if (this.isSameYear(date1, moment())) return date1.format('DD MMM');

        return date1.format('DD MMM YYYY');
      }

      if (date1.format('MM-YYYY') == date2.format('MM-YYYY')) {
        date =
          date1.format('DD') +
          ' - ' +
          date2.format('DD') +
          ' ' +
          date1.format('MMM');

        if (this.isSameYear(date1, moment())) return date;

        return date + ', ' + date1.format('YYYY');
      }

      if (this.isSameYear(date1, date2)) {
        date = date1.format('DD MMM') + ' - ' + date2.format('DD MMM');

        if (this.isSameYear(date1, moment())) return date;

        return date + ', ' + date1.format('YYYY');
      }

      return date1.format('DD/MM/YYYY') + ' - ' + date2.format('DD/MM/YYYY');
    },
    isSameYear(date1, date2) {
      return date1.format('YYYY') == date2.format('YYYY');
    },
    isVisibilityByMonth(date0, date1) {
      return (
        date0.format('YYYY-MM-DD') ==
          date0.clone().startOf('month').format('YYYY-MM-DD') &&
        date1.format('YYYY-MM-DD') ==
          date1.clone().endOf('month').format('YYYY-MM-DD') &&
        date0.format('YYYY-MM') == date1.format('YYYY-MM')
      );
    },
    mask(type, value) {
      return maskBr[type](value);
    },
    datetimeBr(datetime, format = 'DD/MM/YYYY HH:mm') {
      return moment(datetime).format(format);
    },
    dateBrToEn(data) {
      return convertDateBrToEn(data);
    },
    async findAddressByZipCode(zipCode) {
      if (validateBr.cep(zipCode)) return await cep(zipCode);

      return null;
    },
  },
  watch: {
    showTableSelect() {
      // console.log(this.showTableSelect)
    },
  },
};

Vue.mixin(globalMixin);

import router from './router';

const appInstance = new Vue({
  // components: { VueCsvImport  },
  /* sockets: {}, */
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

store.$vueInstance = appInstance;

import './plugins/resizable-table';
import slice from 'lodash/slice';
