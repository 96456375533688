const CLIENT_TYPE = {
  PERSON: 1,
  COMPANY: 2
};

const CLIENT_NAMES = {
  SINGULAR: 'cliente',
  PLURAL: 'clientes',
  TITLE_SINGULAR: 'Cliente',
  TITLE_PLURAL: 'Clientes'
};

const PERSON_NAMES = {
  SINGULAR: 'pessoa',
  PLURAL: 'pessoas',
  TITLE_SINGULAR: 'Pessoa',
  TITLE_PLURAL: 'Pessoas'
};

const COMPANY_NAMES = {
  SINGULAR: 'empresa',
  PLURAL: 'empresas',
  TITLE_SINGULAR: 'Empresa',
  TITLE_PLURAL: 'Empresas'
};

const CLIENT_ENUM = {
  [CLIENT_TYPE['PERSON']]: PERSON_NAMES,
  [CLIENT_TYPE['COMPANY']]: COMPANY_NAMES
};

module.exports = {
  CLIENT_TYPE,
  CLIENT_ENUM,
  CLIENT_NAMES,
  COMPANY_NAMES,
  PERSON_NAMES
};
