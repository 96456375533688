import {
  CREATE_TRIBUTE_GROUP,
  EDIT_TRIBUTE_GROUP,
  DELETE_TRIBUTE_GROUP,
  LIST_TRIBUTES_GROUPS
} from './mutation-types';

export default {
  [CREATE_TRIBUTE_GROUP]: (state, { tributeGroup }) => {
    state.tributesGroups.push(tributeGroup);
  },
  [EDIT_TRIBUTE_GROUP]: (state, { tributeGroup }) => {
    const index = state.tributesGroups.findIndex(t => t.id === tributeGroup.id);
    state.tributesGroups.splice(index, 1, tributeGroup);
  },
  [DELETE_TRIBUTE_GROUP]: (state, { id }) => {
    const index = state.tributesGroups.findIndex(t => t.id === id);

    if (index >= 0) state.tributesGroups.splice(index, 1);
  },
  [LIST_TRIBUTES_GROUPS]: (state, { tributesGroups }) => {
    state.tributesGroups = tributesGroups;
  }
};
