import { mapCarrier, mapCarriers } from '../utils/helpers/carriers';

/* eslint eqeqeq: "off" */

const findCarrierProducts = (products, carrierId) => {
  return products.filter(
    p => p.carriers.filter(sup => carrierId == sup.id).length > 0
  );
};

export default {
  carriers: state => {
    const { carriers } = state;

    return mapCarriers(carriers);
  },
  getCarrier: state => id => {
    const carrier = state.carriers.find(carrier => carrier.id == id);

    return mapCarrier(carrier);
  },
  getCarrierWithoutMasks: state => id => {
    const carrier = state.carriers.find(carrier => carrier.id == id);

    return {
      ...carrier,
    };
  }
};
