import {
  mapUsers,
  mapUser,
  mapUserRoles,
  mapVendor,
  mapVendors,
} from './../utils/helpers/users';

/* eslint eqeqeq: "off" */

export default {
  users: (state) => mapUsers(state.users, state.categories),
  userRoles: (state) => mapUserRoles(state.userRoles),
  permissions: (state) => state.permissions,
  vendors: (state) => mapVendors(state.vendors),
  getVendor: (state) => (id) =>
    mapVendor(state.vendors.find((vendor) => vendor.id == id)),
  getUser: (state) => (id) =>
    mapUser(state.users.find((user) => user.id == id)),
  getUserRole: (state) => (id) => state.userRoles.find((ap) => ap.id == id),
  getUserWithoutMasks: (state) => (id) =>
    state.users.find((user) => user.id == id),
};
