import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/pdv/cashbox';

export default {
  getAll: () => apiClient.get(suffixEndpoint),
  getCashBoxOperations: (id, page, limit, date, all) =>
    apiClient.get(`${suffixEndpoint}/cash-box-operations/${id}`, {
      params: { page, limit, date, all },
    }),
  getCashBoxOperationsTableLength: (id, date) =>
    apiClient.get(`${suffixEndpoint}/cash-box-table-length/${id}`, {
      params: { date },
    }),
  getCashBoxByUserId: (userId) =>
    apiClient.get(`${suffixEndpoint}/user/${userId}/cash-box`),
  getCashBoxLastOpenOrders: (cashBoxId, date) =>
    apiClient.get(`${suffixEndpoint}/cash-box/${cashBoxId}/last-sales`, {
      params: { date },
    }),
  getCashBoxLastOpenDevolutions: (cashBoxId, date) =>
    apiClient.get(`${suffixEndpoint}/cash-box/${cashBoxId}/last-devolutions`, {
      params: { date },
    }),
  create: (data) => {
    return apiClient.post(suffixEndpoint, data);
  },
  saveCashBoxBalance: (balance) =>
    apiClient.post(`${suffixEndpoint}/cash-box/save-balance`, balance),
  getCashBoxBalanceHistoric: ({ page, limit, date, cashBoxId, all }) =>
    apiClient.get(`${suffixEndpoint}/cash-box/get-balance`, {
      params: {
        page,
        limit,
        date,
        cashBoxId,
        all,
      },
    }),
  getCashBoxHistoricTableLength: ({ id, date }) =>
    apiClient.get(`${suffixEndpoint}/cash-box-historic-table-length/${id}`, {
      params: { date },
    }),
  createCashboxOperation: (data) => {
    return apiClient.post(`${suffixEndpoint}/operation`, data);
  },
  edit: (data) => {
    return apiClient.put(`${suffixEndpoint}/${data.id}`, data);
  },
  // delete: id => {
  //   return apiClient.delete(`/${suffixEndpoint}/${id}`);
  // }
};
