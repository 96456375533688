import * as types from './mutation-types';

export default {
  [types.GET_ALL_CLIENTS]: (state, { clients }) => {
    state.clients = clients;
  }, 
  [types.GET_ALL_DEBTORS_CLIENTS]: (state, { clients }) => {
    state.debtorsClients = clients;
  },
  [types.CREATE_CLIENT]: (state, { client }) => {
    state.clients.push(client);
  },
  [types.EDIT_CLIENT]: (state, { client }) => {
    const indice = state.clients.findIndex((t) => t.id === client.id);
    state.clients.splice(indice, 1, client);
  },
  [types.DELETE_CLIENT]: (state, { client }) => {
    const indice = state.clients.findIndex((t) => t.id === client.id);

    if (indice >= 0) state.clients.splice(indice, 1);
  },
  [types.LIST_CLIENTS]: (state, { clients }) => {
    state.clients = clients;
  },
  [types.CREATE_CLIENT_DEBIT_GROUP]: (state, { clientsDebitGroup }) => {
    state.clientsDebitGroups.push(clientsDebitGroup);
  },
  [types.EDIT_CLIENT_DEBIT_GROUP]: (state, { clientsDebitGroup }) => {
    const indice = state.clientsDebitGroups.findIndex(
      (t) => t.id === clientsDebitGroup.id
    );

    if (indice >= 0)
      state.clientsDebitGroups.splice(indice, 1, clientsDebitGroup);
  },

  [types.LOAD_CLIENT_SALES]: (state, { sales }) => {
    state.allClientSales = sales;
  },
  [types.DELETE_CLIENT_DEBIT_GROUP]: (state, { clientsDebitGroupId }) => {
    const indice = state.clientsDebitGroups.findIndex(
      (t) => t.id === clientsDebitGroupId
    );

    if (indice >= 0) state.clientsDebitGroups.splice(indice, 1);
  },
  [types.LIST_CLIENT_DEBIT_GROUP]: (state, { clientsDebitGroups }) => {
    state.clientsDebitGroups = clientsDebitGroups;
  },
  [types.SET_LOADING_CLIENTS]: (state, { isLoadingClients }) => {
    state.isLoadingClients = isLoadingClients;
  },
  [types.TABLES_LENGTH]: (state, { clients }) => {
    state.clientsLength = clients;
  },
};
