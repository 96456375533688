import { STATUS_NAME, PAYMENT_METHODS, NF_TYPES, TYPES } from './../enums';
import { mapAddress } from '@/utils/helpers/address';
import { datetimeBr } from '@/utils/helpers/date';
import { currency } from '@/utils/helpers/currency';
import { maskBr } from 'js-brasil';

const getNfStatusName = order => {
  const ambiente = order.isTest ? '(Homologação)' : '(Produção)';
  const contingencia = order.isContingency ? ', em Contingência' : '';
  if (order.nfeAccessKey) {
    if (order.statusId == 4) return 'Emitido ' + ambiente + contingencia;
    else if (order.statusId == 5) return 'Cancelado ' + ambiente;
    return 'Emitido (Sem status) ' + ambiente + contingencia;
  } else {
    return 'Não emitido';
  }
};

const mapOrder = orderParam => {
  let order = orderParam;
  if (orderParam && orderParam.isSketch) {
    order = { ...orderParam.data, sketchId: order.id };
    order.isSketch = true;
    order.createdAt = orderParam.createdAt;
    order.updatedAt = orderParam.updatedAt;
  }
  let status = ""
  if (order) {
    status = NF_TYPES[order.nfMod]
      ? getNfStatusName(order)
      : STATUS_NAME[order.statusId];
  }
  let total = 0;
  let totalReturned = 0;
  if (order.orderProducts) {
    order.orderProducts.map((op) => {
      totalReturned += op.returnedQuantity;
      total += op.quantity;
    });
  }

  return {
    ...order,
    client: order.user
      ? order.user.name
      : order.client
        ? order.client.name
        : order.supplier
          ? order.supplier.name
          : '',
    clientObject: order.client || order.user || order.supplier,
    contact: order.user
      ? maskBr.telefone(order.user.phone)
      : order.client
        ? maskBr.telefone(order.client.phone)
        : '',
    formattedCreatedAt: datetimeBr(order.createdAt),
    fromText: TYPES[order.type],
    nfTypeText: NF_TYPES[order.nfMod] || 'Cupom não fiscal',
    amount: currency(order.amount),
    paymentMethodDescription: PAYMENT_METHODS[+order.paymentMethod - 1],
    address: mapAddress(order),
    status,
    orderRef: order,
    isTotalDevoluted: totalReturned >= total ? true : false,
    isCanceled: order.statusId == 5 ? true : false
  };
};

const mapOrders = orders => {
  // console.log(orders)
  if (orders.length > 0) {
    return orders.map(order => mapOrder(order));
  }
};

export { mapOrders, mapOrder };
