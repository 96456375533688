import Vue from 'vue';
import VueRouter from 'vue-router';

import authRoutes from '@/modules/auth/router/';
import productsRoutes from '@/modules/products/router/';
import ordersRoutes from '@/modules/ordersRequest/router/';
import configurationsRoutes from '@/modules/configurations/router/';
import reportsRoutes from '@/modules/reports/router/';
import clientsRoutes from '@/modules/clients/router/';
import financialRoutes from '@/modules/financial/router';
import nfeRoutes from '@/modules/nfe/router';

import authMiddleware from '@/modules/auth/router/auth-middleware';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...ordersRoutes,
  ...productsRoutes,
  ...configurationsRoutes,
  ...reportsRoutes,
  ...clientsRoutes,
  ...financialRoutes,
  ...nfeRoutes,
  {
    path: '',
    redirect: '/financeiro'
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

router.beforeEach(authMiddleware);

const checkRouterPermissions = (loggedUser, router) => {
  try {
    return true;

    let permissionsToCheck = [];

    if (router.meta && router.meta.permissions)
      permissionsToCheck = router.meta.permissions;

    if (!loggedUser || !loggedUser.role) return false;
    if (loggedUser.role.name == 'Administrador') return true;
    else {
      const userPermissions = loggedUser.role.permissions.map(
        ({ permission }) => permission
      );

      return (
        permissionsToCheck.length > 0 &&
        permissionsToCheck.every(p => userPermissions.includes(p))
      );
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const pagePermissions = {
  view_sale_historic: 'sale-historic',
  view_clients: 'client-list',
  view_products: 'product-list',
  view_nfe: 'nfe-module',
  view_pdv: 'pdv'
};

const getInitialPageOfUser = loggedUser => {
  const permissions = loggedUser.role.permissions.map(
    ({ permission }) => permission
  );

  if (permissions.length == 0) return 'login';

  const firstViewPermission = permissions.find(perm =>
    perm.startsWith('view_')
  );

  return pagePermissions[firstViewPermission];
};

router.beforeEach(async (to, from, next) => {
  try {
    if (
      !(
        store &&
        store.state &&
        store.state.users &&
        store.state.users.loggedUser &&
        store.state.users.loggedUser.id
      )
    ) {
      await store.dispatch('users/loadLoggedUser');
    }

  } catch (err) {
    if (err && err.response && err.response.status == 401) {
      next();
    } else if (err && err.response && err.response.status == 405) {
      // alert(1231312);
    } else {
      return;
    }
  }

  // alert('passou');

  try {
    if (to.matched.some(record => record.name == 'pdv')) {
      store.dispatch('setIsAppMenuVisible', false);
    } else if (!store.state.isAppMenuVisible) {
      store.dispatch('setIsAppMenuVisible', true);
    }

    if (store && store.state && store.state.users) {
      // alert('verify');
      if (
        to.matched.some(record => {
          return checkRouterPermissions(store.state.users.loggedUser, record);
        })
      ) {
        next();
      } else {
        next({ name: getInitialPageOfUser(store.state.users.loggedUser) });
        // next({ name: 'client-list' });
      }
    } else {
    }
  } catch (err) {
    alert(err);
    next();
  }
  // });
});

export default router;
