export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const LIST_USERS = 'LIST_USERS';
export const LOAD_LOGGED_USER = 'LOAD_LOGGED_USER';
export const LOAD_PERMISSIONS = 'LOAD_PERMISSIONS';
export const SET_LOADING = 'SET_LOADING';

export const CREATE_USER_ROLE = 'CREATE_USER_ROLE';
export const EDIT_USER_ROLE = 'EDIT_USER_ROLE';
export const DELETE_USER_ROLE = 'DELETE_USER_ROLE';
export const LIST_USER_ROLES = 'LIST_USER_ROLES';

export const CREATE_VENDOR = 'CREATE_VENDOR';
export const EDIT_VENDOR = 'EDIT_VENDOR';
export const DELETE_VENDOR = 'DELETE_VENDOR';
export const LIST_VENDORS = 'LIST_VENDORS';