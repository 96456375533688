import AccountService from '../services/accounts-service';
import { mensageTest } from '@/utils/helpers/testPermission'
import * as types from './mutation-types';

export default {
  createFinancialAccount: async ({ commit }, { account }) => {
    const response = await AccountService.create(account);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.CREATE_FINANCIAL_ACCOUNT, { financialAccount: response.data });

    return response.data;
  },
  editFinancialAccount: async ({ commit }, { account }) => {
    const response = await AccountService.edit(account);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.EDIT_FINANCIAL_ACCOUNT, { financialAccount: response.data });

    return response.data;
  },
  deleteFinancialAccount: async ({ commit }, accountId) => {
    await AccountService.delete(accountId);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.DELETE_FINANCIAL_ACCOUNT, { accountId });
  },
  getAllFinancialAccounts: async ({ commit }) => {
    try {
      const response = await AccountService.getAll();

      commit(types.LIST_FINANCIAL_ACCOUNT, {
        financialAccounts: response.data
      });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  }
};
