import CarriersService from '../services/carriers-service';
import { mensageTest } from '@/utils/helpers/testPermission'
import * as types from './mutation-types';

export default {
  createCarrier: ({ commit }, { carrier }) => {
    return new Promise((resolve, reject) => {
      CarriersService.create(carrier)
        .then(response => {
          if(response.status == 405){ // permission error
            mensageTest(response)
          }
          commit(types.CREATE_CARRIER, { carrier: response.data });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  editCarrier: async ({ commit }, { carrier }) => {
    const response = await CarriersService.edit(carrier);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.EDIT_CARRIER, { carrier: response.data });
  },
  deleteCarrier: async ({ commit }, { carrier = {}, id = null  }) => {
    if(!carrier.id) carrier.id = id;
    await CarriersService.delete(carrier.id);
    if(response.status == 405){ // permission error
      mensageTest(response)
    }
    commit(types.DELETE_CARRIER, { carrier });
  },
  getCarriers: async ({ commit }) => {
    try {
      const response = await CarriersService.getAll();
      
      commit(types.LIST_CARRIERS, { carriers: response.data });
    } catch (erro) {
      // alert(erro);
      // commit(types.SETAR_ERRO, { erro });
    }
  }
};
