import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
  ordersRequests: [],
  tags: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
