const suffixEndpoint = '/tags';


import apiClient from '@/utils/api-client-without-base-url';

export default {
  create: data => {
    return apiClient.post(suffixEndpoint, { ...data })
  },
  getAll: () => apiClient.get(suffixEndpoint),
  edit: data => {
    return apiClient.put(`${suffixEndpoint}/${data.saveLayout.id}`, data)
  },
  delete: id => {
    return apiClient.delete(`${suffixEndpoint}/${id}`)
  },
}