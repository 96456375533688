import { mapSupplier, mapSuppliers } from './../utils/helpers/suppliers';

/* eslint eqeqeq: "off" */

const findSupplierProducts = (products, supplierId) => {
  return products.filter(
    p => p.suppliers.filter(sup => sup.id != undefined ? supplierId == sup.id : supplierId == sup).length > 0
  );
};

export default {
  suppliers: state => {
    const { suppliers, products } = state;

    const suppliersWithProducts = suppliers.map(s => ({
      ...s,
      products: findSupplierProducts(products, s.id)
    }));

    return mapSuppliers(suppliersWithProducts);
  },
  getSupplier: state => id => {
    const supplier = state.suppliers.find(supplier => supplier.id == id);

    const supplierWithProducts = {
      ...supplier,
      products: findSupplierProducts(state.products, supplier.id)
    };

    return mapSupplier(supplierWithProducts);
  },
  getSupplierWithoutMasks: state => id => {
    const supplier = state.suppliers.find(supplier => supplier.id == id);

    return {
      ...supplier,
      products: findSupplierProducts(state.products, supplier.id)
    };
  }
};
