<template>
  <!-- <toggle-switch
    :options="options"
    :class="type"
    @change="$emit('change', $event)"
    :value="value"
  /> -->
  <v-btn-toggle
    color="primary"
    borderless
    background-color="var(--v-backgroundSecondary-base)"
    :style="{width: width + 'px'}"
    :value="value"
  >
    <v-btn
      style="width: 125px"
      v-for="(label, index) in labels"
      :key="index"
      depressed
      :value="label.name"
      @click="emitSelected(label.name)"
      small
      class="py-4"
      :ripple="false"
      text
      >{{ label.name }}</v-btn
    >
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    // type: {
    //   type: String,
    //   default: 'together' // options: Together, separate
    // },
    labels: {
      type: Array
    },
    // default: {
    //   type: String
    // },
    // width: {
    //   type: Number,
    //   default: 17
    // }
  },
  // watch: {
  //   value(val){
  //     alert(val);
  //   }
  // },
  computed:{
    width(){
      return 125 * this.labels.length
    }
  },
  methods: {
    emitSelected(event) {
      this.$emit('change', event);
    },
  //   together () {
  //     return {
  //       layout: {
  //         backgroundColor: 'var(--v-backgroundSecondary-base)',
  //         selectedBackgroundColor: 'var(--v-backgroundSecondary-base)'
  //       },
  //       size: {
  //         height: 2,
  //         padding: 0.0,
  //         width: this.width
  //       }
  //     };
  //   },
  //   separate () {
  //     return {
  //       layout: {
  //         backgroundColor: 'var(--v-backgroundPanel-base)',
  //         selectedBackgroundColor: 'var(--v-backgroundPanel-base)'
  //       },
  //       size: {
  //         height: 2.5,
  //         padding: 0.5,
  //         width: this.width
  //       }
  //     };
  //   }
  // },
  // data () {
  //   const { layout, size } = this[this.type]();

  //   return {
  //     options: {
  //       layout: {
  //         color: '#575962',
  //         fontFamily: 'poppins',
  //         selectedColor: 'var(--v-primary-base)',
  //         borderColor: 'var(--v-backgroundSecondary-base)',
  //         fontWeightSelected: 'normal',
  //         ...layout
  //       },
  //       size: {
  //         fontSize: 0.8,
  //         ...size
  //       },
  //       items: {
  //         preSelected: this.default,
  //         labels: this.labels
  //       }
  //     }
  //   };
  }
};
</script>
<style>
.toggle-switch .selected.active {
  border: 1px solid var(--v-primary-base) !important;
}

.toggle-switch.separate label {
  border-radius: 8px !important;
  margin: 0px 5px !important;
}

.toggle-switch li label{
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.toggle-switch.together li:first-child label {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.toggle-switch.together li:last-child label {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
</style>
