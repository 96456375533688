import store from "@/store/index.js"
const mensageTest = (response) => {
    if (response && response.status == 405) { ///not user permission messages
        store.dispatch('showMessage', {
            message: response.data,
            color: 'normal'
        }, { root: true });
    } else if (response && response.config.url == '/orders' && response.config.method == 'post' && response.data) { /// api-nfe errors
        store.dispatch('showMessage', {
            message: response.data.message,
            color: 'normal'
        }, { root: true });
    }
};


export { mensageTest };