import * as types from './mutation-types';

import orderState from '../../../../orders/store/index'

export default {
  [types.CREATE_FINANCIAL_CATEGORIES]: (state, { financialCategory }) => {
    state.financialCategories.push(financialCategory);
  },
  [types.UPSERT_FINANCIAL_CATEGORIES]: (state, { financialCategory }) => {
    const index = state.financialCategories.findIndex(
      t => t.id === financialCategory.id
    );
    if (index >= 0) {
      state.financialCategories.splice(index, 1, financialCategory);
    } else {
      state.financialCategories.push(financialCategory);
    }
  },
  [types.LIST_FINANCIAL_CATEGORIES]: (state, { financialCategories }) => {
    state.financialCategories = financialCategories;
  },
  [types.EDIT_FINANCIAL_CATEGORIES]: (state, { financialCategory }) => {
    const index = state.financialCategories.findIndex(
      t => t.id === financialCategory.id
    );
    if (+index >= 0) {
      state.financialCategories.splice(index, 1, financialCategory);
    }
  },
  [types.DELETE_FINANCIAL_TRANSACTION]: (state, { financialTransactionId }) => {
    const index = state.financialTransactions.findIndex(
      t => t.id === financialTransactionId
    );
    if (+index >= 0) {
      state.financialTransactions.splice(index, 1);
    }
  },
  [types.DELETE_FINANCIAL_CATEGORY]: (state, { categoryId }) => {
    const index = state.financialCategories.findIndex(
      t => t.id === categoryId
    );
    if (+index >= 0) {
      state.financialCategories.splice(index, 1);
    }
  },
  [types.CREATE_TRANSFER_BETWEEN_ACCOUNTS]: (state, { transactions }) => {
    state.financialTransactions = state.financialTransactions.concat(transactions);
  },
  [types.CREATE_FINANCIAL_TRANSACTION]: (state, { financialTransaction }) => {
    state.financialTransactions.push(financialTransaction);
  },
  [types.EDIT_FINANCIAL_TRANSACTION]: (state, { financialTransaction }) => {
    const index = state.financialTransactions.findIndex(
      t => t.id === financialTransaction.id
    );
    if (+index >= 0) {
      state.financialTransactions.splice(index, 1, financialTransaction);
    }
  },
  [types.LIST_FINANCIAL_TRANSACTION]: (state, { financialTransactions, type }) => {
    if (type == 0) {
      state.financialTransactions = financialTransactions;
    }
    if (type == 1) {
      state.allFinancialTransactions = financialTransactions
    }
  },
  [types.CREATE_DEVOLUTION]: (state, { devolution, financialTransaction, orderProduct }) => {
    state.devolutions.push(devolution)
    state.financialTransactions.push(financialTransaction);

    let selectedOrder = []
    let selectedOrderProduct = []
    orderState.state.orders.map((order) => {
      if (order.orderProducts) {
        order.orderProducts.map((op) => {
          if (op.id == orderProduct.id) {
            selectedOrderProduct = op
            selectedOrder = order
            return
          }
        })
      }
    })
    const index = orderState.state.orders.findIndex(o => o.id === selectedOrder.id)
    const orderProductIndex = orderState.state.orders[index].orderProducts.findIndex(op => op.id === selectedOrderProduct.id)
    if (+index >= 0 && orderProductIndex >= 0) {
      orderState.state.orders[index].orderProducts.splice(orderProductIndex, 1, orderProduct);
    }
  },
  [types.LIST_DEVOLUTIONS]: (state, { devolutions }) => {
    state.devolutions = devolutions;
  },
  [types.TABLES_TRANSACTIONS_LENGTH]: (state, { transactions }) => {
    state.transactionsLength = transactions;
  },

  [types.DECREASE_RETURNED_QUANTITY]: (state, { orderProduct }) => {
    let selectedOrder = []
    let selectedOrderProduct = []
    orderState.state.orders.map((order) => {
      if (order.orderProducts) {
        order.orderProducts.map((op) => {
          if (op.id == orderProduct.id) {
            selectedOrderProduct = op
            selectedOrder = order
            return
          }
        })
      }
    })
    const index = orderState.state.orders.findIndex(o => o.id === selectedOrder.id)
    const orderProductIndex = orderState.state.orders[index].orderProducts.findIndex(op => op.id === selectedOrderProduct.id)
    if (+index >= 0 && orderProductIndex >= 0) {
      orderState.state.orders[index].orderProducts.splice(orderProductIndex, 1, orderProduct);
    }
  }
};

