import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/vendors';

export default {
  getAll: () => apiClient.get(suffixEndpoint),
  getAllActives: () => {
    return apiClient.get(`${suffixEndpoint}/actives`);
  },
  get: id => {
    return apiClient.get(`${suffixEndpoint}/${id}`);
  },
  create: data => {
    return apiClient.post(suffixEndpoint, data);
  },
  edit: data => {
    return apiClient.put(`${suffixEndpoint}`, data);
  },
  delete: id => {
    return apiClient.delete(`${suffixEndpoint}/${id}`);
  },
};
