const mapTags = tags => {
    return tags.map((tag) => {
        return tag
    });

};
const mapTag = tag => {
    return {
        ...tag,
    };
};
// const mapDragDatas = tagsDragDatas =>{
//     return tagsDragDatas.map((data) => {
//         return data
//     });
// }
export { mapTags , mapTag };