export const CREATE_BILL = 'CREATE_BILL';
export const LIST_BILL = 'LIST_BILL';
export const EDIT_BILL = 'EDIT_BILL';
export const EDIT_BILLS = 'EDIT_BILLS';
export const DELETE_BILL = 'DELETE_BILL';
export const UPDATE_BILLS_VALUE = 'UPDATE_BILLS_VALUE'
export const LIST_BILLS_TO_PAY = 'LIST_BILLS_TO_PAY'
export const LIST_BILLS_TO_RECIEVE = 'LIST_BILLS_TO_RECIEVE'
export const TABLES_BILLS_TO_PAY_LENGTH = 'TABLES_BILLS_TO_PAY_LENGTH'
export const TABLES_BILLS_TO_RECIEVE_LENGTH = 'TABLES_BILLS_TO_RECIEVE_LENGTH'

export const LOAD_RECURRENCE_TYPES = 'LOAD_RECURRENCE_TYPES';