import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  erro: undefined,
  clients: [],
  clientsDebitGroups: [],
  isLoadingClients: true,
  clientsLength: 0,
  allClientSales: [],
  debtorsClients: [],
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
