import { maskBr, validateBr } from 'js-brasil';

import { currency } from '@/utils/helpers/currency';
import moment from 'moment';

moment.locale('pt-br');

const maskPhone = phone => {
  if (!phone) {
    return '';
  }

  return validateBr.telefone(phone)
    ? maskBr.telefone(phone)
    : maskBr.celular(phone);
};

const maskCpfCnpj = cpfCnpj => {
  if (!cpfCnpj) {
    return '';
  }

  return validateBr.cpf(cpfCnpj) ? maskBr.cpf(cpfCnpj) : maskBr.cnpj(cpfCnpj);
};

const mapSupplier = supplier => {
  // console.log(supplier.orders);
  return {
    ...supplier,
    phone: maskPhone(supplier.phone),
    cnpj: maskCpfCnpj(supplier.cnpj),
    productNameList: supplier.products.map(p => p.name).join(', '),
    products: supplier.products.map(p => ({
      ...p,
      price: currency(p.price)
    })),
    orders: supplier.orders ? mapImportedNFs(supplier.orders) : []
  };
};

const mapImportedNFs = orders => {
  return orders.map(nf => {
    const { infNFe } = nf.data.nfeProc != undefined ? nf.data.nfeProc.infNFe != undefined ? nf.data.nfeProc : nf.data.nfeProc.NFe : nf.data.NFe;
    const { nNF } = infNFe.ide;
    const { vNF } = infNFe.total.ICMSTot;

    return {
      ...nf,
      formattedCreatedAt: moment(nf).format('DD/MM/YYYY HH:mm'),
      data: infNFe,
      nNF,
      vNF
    };
  });
};

const mapSuppliers = suppliers => {
  return suppliers.map(supplier => mapSupplier(supplier));
};

export { mapSupplier, mapSuppliers };
