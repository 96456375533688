const Container = () => import('../views/Container');

const FinancialInitialPage = () => import('../views/FinancialInitialPage');
const PDV = () => import('../submodules/pdv/views/PDV');
// const PDV = () => import('../views/PdvOld');

export default [
  {
    path: '/financeiro',
    component: Container,
    meta: { requiresAuth: true, permissions: ['view_sale_historic'] },
    children: [
      {
        path: '',
        name: 'financial-page',
        component: FinancialInitialPage
      },
      {
        path: '/pdv',
        name: 'pdv',
        meta: { permissions: ['view_pdv']},
        component: PDV
      }
    ]
  }
];
