<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 41 41"
    v-bind="$attrs"
  >
    <g transform="translate(-206 -239)">
      <g transform="translate(206 239)">
        <path
          class="c"
          d="M24.525,24.108H22.441V2.441h.833a.394.394,0,0,0,.417-.417V.358a.394.394,0,0,0-.417-.417H1.608a.394.394,0,0,0-.417.417V2.025a.394.394,0,0,0,.417.417h.833V24.108H.358a.417.417,0,0,0,0,.833H24.525a.417.417,0,0,0,0-.833ZM2.025.775H22.858v.833H2.025Zm8.333,23.333V17.441h1.667v6.667H10.358Zm2.5,0V17.441h1.667v6.667H12.858Zm2.5,0V17.025a.394.394,0,0,0-.417-.417h-5a.394.394,0,0,0-.417.417v7.083H3.275V2.441H21.608V24.108Z"
          transform="translate(0.059 0.059)"
        />
        <path
          class="c"
          d="M12.025,4.941H5.358a.394.394,0,0,0-.417.417V8.691a.394.394,0,0,0,.417.417h6.667a.394.394,0,0,0,.417-.417V5.358A.394.394,0,0,0,12.025,4.941Zm-6.25.833h2.5v2.5h-2.5v-2.5Zm5.833,2.5h-2.5v-2.5h2.5Z"
          transform="translate(-0.775 -0.775)"
        />
        <path
          class="c"
          d="M9.108,19.941H5.358a.394.394,0,0,0-.417.417v3.333a.394.394,0,0,0,.417.417h3.75a.394.394,0,0,0,.417-.417V20.358A.394.394,0,0,0,9.108,19.941Zm-.417,3.333H5.775v-2.5H8.691Z"
          transform="translate(-0.775 -3.275)"
        />
        <path
          class="c"
          d="M23.608,19.941h-3.75a.394.394,0,0,0-.417.417v3.333a.394.394,0,0,0,.417.417h3.75a.394.394,0,0,0,.417-.417V20.358A.394.394,0,0,0,23.608,19.941Zm-.417,3.333H20.275v-2.5h2.917Z"
          transform="translate(-3.191 -3.275)"
        />
        <path
          class="c"
          d="M23.025,4.941H16.358a.394.394,0,0,0-.417.417V8.691a.394.394,0,0,0,.417.417h6.667a.394.394,0,0,0,.417-.417V5.358A.394.394,0,0,0,23.025,4.941Zm-6.25.833h2.5v2.5h-2.5Zm5.833,2.5h-2.5v-2.5h2.5Z"
          transform="translate(-2.608 -0.775)"
        />
        <path
          class="c"
          d="M12.025,11.941H5.358a.394.394,0,0,0-.417.417v3.333a.394.394,0,0,0,.417.417h6.667a.394.394,0,0,0,.417-.417V12.358A.394.394,0,0,0,12.025,11.941Zm-6.25.833h2.5v2.5h-2.5v-2.5Zm5.833,2.5h-2.5v-2.5h2.5Z"
          transform="translate(-0.775 -1.941)"
        />
        <path
          class="c"
          d="M23.025,11.941H16.358a.394.394,0,0,0-.417.417v3.333a.394.394,0,0,0,.417.417h6.667a.394.394,0,0,0,.417-.417V12.358A.394.394,0,0,0,23.025,11.941Zm-6.25.833h2.5v2.5h-2.5Zm5.833,2.5h-2.5v-2.5h2.5Z"
          transform="translate(-2.608 -1.941)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'CompanyIcon',
  props: {
    size: {
      default: 41
    }
  }
};
</script>
