import apiClient from '@/utils/api-client-without-base-url';

const suffixEndpoint = '/financial/categories';

export default {
  create: (data) => apiClient.post(`${suffixEndpoint}`, data),
  update: (data) => apiClient.put(`${suffixEndpoint}/${data.id}`, data),
  getAll: () => apiClient.get(`${suffixEndpoint}`),
  delete: (categoryId) => apiClient.delete(`${suffixEndpoint}/${categoryId}`),
};
