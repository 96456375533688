import apiClient from '@/utils/api-client';

export default {
  get: id => {
    return apiClient.get('/');
  },
  edit: data => {
    return apiClient.put('/', data);
  },
  editHour: data => {
    return apiClient.put('/hour', data);
  },
  saveDeliveryValue: data => {
    return apiClient.put('/save-delivery-value', data);
  },
  editVendors: data => {
    return apiClient.put('/save-vendors-value', data)
  },
  certificateValidations: () => {
    return apiClient.get(`/certificate-validations`);
  },
  removeCert: () => {
    return apiClient.delete(`/certificate`);
  },
  uploadCert: (certData) => {
    return apiClient.put('/certificate', certData)
  }
};
