import {
  CREATE_SUPPLIER,
  EDIT_SUPPLIER,
  DELETE_SUPPLIER,
  LIST_SUPPLIERS,
  UPDATE_PRODUCTS_AFTER_DELETE_SUPPLIER,
  SET_LOADING
} from './mutation-types';

export default {
  [CREATE_SUPPLIER]: (state, { supplier }) => {
    state.suppliers.push(supplier);
  },
  [EDIT_SUPPLIER]: (state, { supplier }) => {
    const indice = state.suppliers.findIndex(t => t.id === supplier.id);
    state.suppliers.splice(indice, 1, supplier);
    let countProducts = 0
    state.products.map((product) => {
      if(product.suppliers){
        let countSuppliers = 0
        product.suppliers.map((supp) => {
          if (supp.id == supplier.id){
            state.products[countProducts].suppliers.splice(countSuppliers, 1, supplier);
          }
          countSuppliers++
        })
      }
      countProducts++
    })
  },
  [DELETE_SUPPLIER]: (state, { supplier }) => {
    const indice = state.suppliers.findIndex(t => t.id === supplier.id);

    if (indice >= 0) state.suppliers.splice(indice, 1);
  },
  [LIST_SUPPLIERS]: (state, { suppliers }) => {
    state.suppliers = suppliers;
  },
  [UPDATE_PRODUCTS_AFTER_DELETE_SUPPLIER]: (state, { supplier }) => {
    const { products } = state;

    state.products = products.map(product => {
      return {
        ...product,
        suppliers: product.suppliers.filter(s => +s.id != +supplier.id)
      };
    });
  },
  [SET_LOADING]: (state, { loading }) => {
    state.loading = loading;
  }
};
