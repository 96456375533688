import * as types from './mutation-types';

export default {
  [types.DELETE_CERT]: (state) => {
    if (state.company.fiscalConfig)
      delete state.company.fiscalConfig.certificado;
  },
  [types.UPLOAD_CERT]: (state, certData) => {
    if (state.company.fiscalConfig) {
      const certificado = certData.get('certificado');
      state.company.fiscalConfig.certificado = certificado;
    }
  },
  [types.EDIT_CONFIG]: (state, { company }) => {
    state.company = company;
  },
  [types.GET_PAYMENT_METHODS]: (state, { paymentMethods }) => {
    state.paymentMethods = paymentMethods;
  },
  [types.CREATE_PAYMENT_METHOD]: (state, { paymentMethod }) => {
    state.paymentMethods.push(paymentMethod);
  },
  [types.EDIT_PAYMENT_METHOD]: (state, { paymentMethod }) => {
    const index = state.paymentMethods.findIndex(
      (p) => p.id === paymentMethod.id
    );
    if (index >= 0) state.paymentMethods.splice(index, 1, paymentMethod);
  },
  [types.DELETE_PAYMENT_METHODS]: (state, { paymentMethods }) => {
    paymentMethods.forEach((paymentMethod) => {
      const index = state.paymentMethods.findIndex(
        (p) => p.id === paymentMethod.id
      );
      if (index >= 0) state.paymentMethods.splice(index, 1);
    });
  },
};
