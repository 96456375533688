import { mapTags , mapTag } from '../utils/helpers/tags';

export default {
    tags: state => {
      const { tags } = state;
      return mapTags(tags);
    },
    getTag: state => id => {
      const tag = state.tags.find(tag => tag.id == id);
      return mapTag(tag);
    },
    // dragDatas: state => {
    //   const { tagsDragDatas } = state;
    //   return mapDragDatas(tagsDragDatas);
    // },
    // getDragData: state => id =>{
    //   const dragData = {}
    //   state.tagsDragDatas[0].map((data) => {
    //     if(data.tagModelId == id){
    //       // dragData.push(data)
    //       dragData[data.fieldName] = data
    //     }
    //   })
    //   return dragData
    // }
  }