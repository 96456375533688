export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const LIST_CLIENTS = 'LIST_CLIENTS';
export const LOAD_CLIENT = 'LOAD_CLIENT';
export const SET_LOADING_CLIENTS = 'SET_LOADING_CLIENTS';
export const LOAD_CLIENT_SALES = 'LOAD_CLIENT_SALES;';
export const TABLES_LENGTH = 'TABLES_LENGTH';
export const GET_ALL_DEBTORS_CLIENTS = 'GET_ALL_DEBTORS_CLIENTS';
// export const UPDATE_CLIENT_FUTURE_CREDIT = 'UPDATE_CLIENT_FUTURE_CREDIT';

export const CREATE_CLIENT_DEBIT_GROUP = 'CREATE_CLIENT_DEBIT_GROUP';
export const EDIT_CLIENT_DEBIT_GROUP = 'EDIT_CLIENT_DEBIT_GROUP';
export const DELETE_CLIENT_DEBIT_GROUP = 'DELETE_CLIENT_DEBIT_GROUP';
export const LIST_CLIENT_DEBIT_GROUP = 'LIST_CLIENT_DEBIT_GROUP';
