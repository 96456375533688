export const csosnListDefault = [
  {
    id: '102',
    name: 'Tributada pelo Simples Nacional sem permissão de crédito'
  },
  {
    id: '103',
    name: 'Isenção do ICMS no Simples Nacional para faixa de receita bruta'
  },
  {
    id: '202',
    name:
      'Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária'
  },
  {
    id: '203',
    name:
      'Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária'
  },
  {
    id: '300',
    name: 'Imune'
  },
  {
    id: '400',
    name: 'Não tributada pelo Simples Nacional'
  },
  {
    id: '500',
    name:
      'ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação'
  },
  {
    id: '900',
    name: 'Outros'
  }
];

export const listaOrigemProdutos = [{
  id: 0,
  name: 'Nacional'
}, {
  id: 1,
  name: 'Estrangeira - Importação direta'
}, {
  id: 2,
  name: 'Estrangeira - Adquirida no mercado interno'
}, {
  id: 3,
  name: 'Nacional, mercadoria ou bem com conteúdo de importação superior a 40% e inferior ou igual a 70%'
}, {
  id: 4,
  name: 'Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos (PPB) de que tratam o Decreto-Lei nº 288/1967, e as Leis nºs 8.248/1991, 8.387/1991, 10.176/2001 e 11.484/2007.'
}, {
  id: 5,
  name: 'Nacional, mercadoria ou bem com conteúdo de importação inferior ou igual a 40%'
}, {
  id: 6,
  name: 'Estrangeira - Importação direta, sem similar nacional, constante em lista de resolução CAMEX e gás natural'
}, {
  id: 7,
  name: 'Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX e gás natural.'
}, {
  id: 8,
  name: 'Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%'
}]
