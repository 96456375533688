const Container = () => import('./../views/Container');
const ConfigurationsPage = () => import('./../views/ConfigurationsPage');

export default [
  {
    path: '/configuracoes',
    component: Container,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'configurations',
        component: ConfigurationsPage
      },
    ]
  }
];
