import * as types from './mutation-types';

export default {
  // users
  [types.CREATE_USER]: (state, { user }) => {
    state.users.push(user);
  },
  [types.EDIT_USER]: (state, { user }) => {
    const index = state.users.findIndex(t => t.id === user.id);
    state.users.splice(index, 1, user);
  },
  [types.DELETE_USER]: (state, { id }) => {
    const index = state.users.findIndex(t => t.id === id);

    if (index >= 0) state.users.splice(index, 1);
  },
  [types.LIST_USERS]: (state, { users }) => {
    state.users = users;
  },
  [types.LOAD_LOGGED_USER]: (state, { user }) => {
    state.loggedUser = user;
  },
  [types.LOAD_PERMISSIONS]: (state, { permissions }) => {
    state.permissions = permissions;
  },

  //vendors

  [types.CREATE_VENDOR]: (state, { vendor }) => {
    state.vendors.push(vendor);
  },
  [types.EDIT_VENDOR]: (state, { vendor }) => {
    const index = state.vendors.findIndex(t => t.id === vendor.id);
    state.vendors.splice(index, 1, vendor);
  },

  [types.DELETE_VENDOR]: (state, { id }) => {
    const index = state.vendors.findIndex(t => t.id === id);

    if (index >= 0) state.vendors.splice(index, 1);
  },
  [types.LIST_VENDORS]: (state, { vendors }) => {
    state.vendors = vendors;
  },

  // access profiles

  [types.CREATE_USER_ROLE]: (state, { userRole }) => {
    state.userRoles.push(userRole);
  },
  [types.EDIT_USER_ROLE]: (state, { userRole }) => {
    const index = state.userRoles.findIndex(t => t.id === userRole.id);
    state.userRoles.splice(index, 1, userRole);
  },
  [types.DELETE_USER_ROLE]: (state, { id }) => {
    const index = state.userRoles.findIndex(t => t.id === id);

    if (index >= 0) state.userRoles.splice(index, 1);
  },
  [types.LIST_USER_ROLES]: (state, { userRoles }) => {
    state.userRoles = userRoles;
  }
};
