import * as types from './mutation-types';

export default {
  [types.CREATE_BILL]: (state, { bill }) => {
    if (Array.isArray(bill)) state.bills = state.bills.concat(bill);
    else state.bills.push(bill);
  },
  [types.LIST_BILL]: (state, { bills }) => {
    state.bills = bills;
  },
  [types.LIST_BILLS_TO_PAY]: (state, { bills }) => {
    state.billsToPay = bills;
  },
  [types.TABLES_BILLS_TO_PAY_LENGTH]: (state, { bills }) => {
    state.billsToPayLength = bills;
  },
  [types.LIST_BILLS_TO_RECIEVE]: (state, { bills }) => {
    state.billsToRecieve = bills;
  },
  [types.TABLES_BILLS_TO_RECIEVE_LENGTH]: (state, { bills }) => {
    state.billsToRecieveLength = bills;
  },
  // [types.PUSH_BILLS]: (state, { bills }) => {
  //   console.log(state.bills)
  //   bills.map((bill) => {
  //     state.bills.push(bill)
  //   })
  // },
  [types.LOAD_RECURRENCE_TYPES]: (state, { recurrenceTypes }) => {
    state.recurrenceTypes = recurrenceTypes;
  },
  [types.EDIT_BILL]: (state, { bill }) => {
    const index = state.bills.findIndex(b => b.id === bill.id);

    // console.log(state.bills[index]);
    if (index >= 0) state.bills.splice(index, 1, bill);
    // console.log(state.bills[index]);
  },
  [types.EDIT_BILLS]: (state, { bills }) => {
    // console.log(bills);
    // const { originBillId: originBillIdToUpdate } = bills[0];
    // const newBills = state.bills.filter(
    //   ({ originBillId, id }) =>
    //     originBillId == originBillIdToUpdate || id == originBillIdToUpdate
    // );
     
    bills.map((newBill) => {
      let index = state.bills.findIndex(b => b.id === newBill.id);
      if (index >= 0){
        state.bills.splice(index, 1, newBill);
      }
    })
    // state.bills = newBills.concat(bills);
  },
  [types.DELETE_BILL]: (state, { billId }) => {
    const index = state.bills.findIndex(b => b.id === billId);
    if (index >= 0) state.bills.splice(index, 1);
  },
  [types.UPDATE_BILLS_VALUE]: (state, { bills }) => {
    bills.map((bill) => {
      const index = state.bills.findIndex(b => b.id === bill.id);
      if (index > -1) {
        state.bills.splice(index, 1, bill);
      }
    })
  }
};
