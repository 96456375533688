import actions from './actions';
import getters from './getters';
import mutations from './mutations';

import cashBoxActions from '../submodules/cashbox/store/actions';
import cashBoxMutations from '../submodules/cashbox/store/mutations';
import cashBoxGetters from '../submodules/cashbox/store/getters';

import accountsActions from '../submodules/accounts/store/actions';
import accountsMutations from '../submodules/accounts/store/mutations';
import accountsGetters from '../submodules/accounts/store/getters';

import financialTransactionsActions from '../submodules/financial-transactions/store/actions';
import financialTransactionsMutations from '../submodules/financial-transactions/store/mutations';
import financialTransactionsGetters from '../submodules/financial-transactions/store/getters';

import billsActions from '../submodules/bills/store/actions';
import billsMutations from '../submodules/bills/store/mutations';
import billsGetters from '../submodules/bills/store/getters';


const state = {
  erro: undefined,
  loggedCashBox: {},
  cashBoxes: [],
  cashBoxOperationsLength: 0,
  financialAccounts: [],
  financialCategories: [],
  financialTransactions: [],
  allFinancialTransactions: [],
  transactionsLength: 0,
  billsToPayLength: 0,
  billsToRecieveLength: 0,
  recurrenceTypes: [],
  bills: [],
  billsToPay: [],
  billsToRecieve: [],
  loading: true,
  devolutions: [],

  balance: {},
  todaySales: [],
  lastSalesByDay: []
};

export default {
  namespaced: true,
  state,
  getters: {
    ...getters,
    ...cashBoxGetters,
    ...accountsGetters,
    ...financialTransactionsGetters,
    ...billsGetters
  },
  mutations: {
    ...cashBoxMutations,
    ...accountsMutations,
    ...financialTransactionsMutations,
    ...billsMutations,
    ...mutations
  },
  actions: {
    ...actions,
    ...cashBoxActions,
    ...accountsActions,
    ...financialTransactionsActions,
    ...billsActions
  }
};
