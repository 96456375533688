import UsersService from './../services/users-service';
import UserRoleService from '../services/user-roles-service';
import VendorsService from '../services/vendors-service'

import * as types from './mutation-types';

export default {
  
  // vendors
  createVendor: ({ commit }, { vendor }) => {
    return new Promise((resolve, reject) => {
      VendorsService.create(vendor)
        .then(response => {
          const { data } = response;
          commit(types.CREATE_VENDOR, { vendor: data });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  editVendor: async ({ commit }, { vendor }) => {
    const { data } = await VendorsService.edit(vendor);
    commit(types.EDIT_VENDOR, { vendor: data });
  },
  deleteVendor: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      VendorsService.delete(id)
        .then(response => {
          commit(types.DELETE_VENDOR, { id });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  getVendors: async ({ commit }) => {
    const response = await VendorsService.getAll();
    const vendors = response.data.map(vendor => {
      return { ...vendor };
    });
    commit(types.LIST_VENDORS, { vendors });
  },

  // users
  createUser: ({ commit, dispatch }, { user }) => {
    return new Promise((resolve, reject) => {
      UsersService.create(user)
        .then(response => {
          const { data } = response;
          commit(types.CREATE_USER, { user: data });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  editUser: async ({ commit }, { user }) => {
    const { data } = await UsersService.edit(user);
    // console.log(data);
    commit(types.EDIT_USER, { user: data });
  },
  deleteUser: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      UsersService.delete(id)
        .then(response => {
          commit(types.DELETE_USER, { id });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  // deleteArrayUsers: async ({ commit }, { ids }) => {
  //   await UsersService.delete(user.id);
  //   commit(types.DELETE_USER, { user });
  // },
  saveProductConfig: async ({ commit }, { config }) => {
    const response = await UsersService.saveProductConfig(config);

    // mappedPermissions: response.data.role.permissions
    //         ? response.data.role.permissions.map(({ permission }) => permission)
    //         : []

    commit(types.LOAD_LOGGED_USER, {
      user: {
        ...response.data,
        role: {
          ...response.data.role,
          mappedPermissions: response.data.role.permissions
            ? response.data.role.permissions.map(({ permission }) => permission)
            : []
        }
      }
    });
  },
  saveConfigSale: async ({ commit }, { config }) => {
    const response = await UsersService.saveConfigSale(config);

    // mappedPermissions: response.data.role.permissions
    //         ? response.data.role.permissions.map(({ permission }) => permission)
    //         : []

    commit(types.LOAD_LOGGED_USER, {
      user: {
        ...response.data,
        role: {
          ...response.data.role,
          mappedPermissions: response.data.role.permissions
            ? response.data.role.permissions.map(({ permission }) => permission)
            : []
        }
      }
    });
  },
  loadLoggedUser: async ({ commit }) => {
    const response = await UsersService.getLoggedUser();
    commit(types.LOAD_LOGGED_USER, {
      user: {
        ...response.data,
        role: {
          ...response.data.role,
          mappedPermissions: response.data.role.permissions
            ? response.data.role.permissions.map(({ permission }) => permission)
            : []
        }
      }
    });


    UsersService.getAllPermissions().then(({ data: permissions }) => {
      commit(types.LOAD_PERMISSIONS, { permissions });

    }).catch(console.error);

  },
  getUsers: async ({ commit }) => {
    const response = await UsersService.getAll();
    // console.log(response.data.filter(user => user.promotion));

    const userId = window.localStorage.getItem('userId');

    const users = response.data.map(user => {
      if (+user.id == +userId) {
        return {
          ...user,
          isTheLoggedUser: true
        };
      } else {
        return { ...user };
      }
    });
    // const users = response.data.filter(user => +user.id != +userId);

    commit(types.LIST_USERS, { users });
  },

  // access profiles
  createUserRole: ({ commit, dispatch }, { userRole }) => {
    return new Promise((resolve, reject) => {
      UserRoleService.create(userRole)
        .then(response => {
          const { data } = response;
          commit(types.CREATE_USER_ROLE, { userRole: data });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  editUserRole: async ({ commit }, { userRole }) => {
    const { data } = await UserRoleService.edit(userRole);
    commit(types.EDIT_USER_ROLE, { userRole: data });
  },
  deleteUserRole: async ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      UserRoleService.delete(id)
        .then(response => {
          commit(types.DELETE_USER_ROLE, { id });
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },
  getUserRoles: async ({ commit }) => {
    const response = await UserRoleService.getAll();
    commit(types.LIST_USER_ROLES, { userRoles: response.data });
  }
};
